import React from "react";
import { extendObservable, makeAutoObservable, runInAction, toJS } from "mobx";
import {
  ApiRequest,
  ExtractApiResponse,
  isEqual,
  Loader,
  MaybeArray,
  Nullable,
  PAGINATION_OPTIONS,
  PaginationList,
  toArrayOptions,
  Typography,
} from "@gemlightbox/core-kit";

import {
  getCatalogs,
  deleteCatalog,
  GetCatalogsFilters,
  putUpdateCatalog,
  PostCreateCatalogData,
  postCreateCatalog,
  CatalogFilters,
  getUserTagManagerInfoCallback,
  getLastUntitledCatalogNumber,
} from "src/api";
import { FiltersType } from "src/external-ts/components";
import { CatalogModel, ProductsExportType } from "src/models";
import {
  CatalogsFiltersQueryType,
  CatalogsSortType,
  ExtendedCatalogModel,
} from "./catalogs.store.types";
import { getCatalogLink, pushDataLayerEvent } from "src/utils";
import { useStores } from "src/hooks";
import { RootStore } from "../root.store";

// TODO: make another styles structure
import rowStyles from "../../external-ts/pages/catalog/catalogs-list/table-view/t-body/row/row.module.css";

export class CatalogsStore {
  private readonly _initialFilters: GetCatalogsFilters = {
    limit: PAGINATION_OPTIONS[0].value,
    page: 1,
    name: "",
    user_id: "all",
    filterBy: "all",
  };

  private readonly _initialSortValues: CatalogsSortType = {
    sortBy: "createdAt",
    sortOrder: "desc",
  };

  private _prevRequest: Nullable<ApiRequest<ExtractApiResponse<typeof getCatalogs>>> = null;
  private _loading = false;
  private _totalCatalogsAmount = 0;
  private _filteredCatalogsAmount = 0;
  private _lastUntitledCatalogNumber = 0;
  private _catalogsList: ExtendedCatalogModel[] = [];
  private _filters: GetCatalogsFilters = this._initialFilters;
  private _sortValues: CatalogsSortType = this._initialSortValues;

  public get loading() {
    return this._loading;
  }

  public get totalCatalogsAmount() {
    return this._totalCatalogsAmount;
  }

  public get filteredCatalogsAmount() {
    return this._filteredCatalogsAmount;
  }

  public get catalogList() {
    return this._catalogsList;
  }

  public get catalogsAmount() {
    return this.catalogList.length;
  }

  public get filters() {
    return this._filters;
  }

  public get sortValues() {
    return this._sortValues;
  }

  public get totalPages() {
    return Math.ceil(this.filteredCatalogsAmount / this.filters.limit);
  }

  public get isOnlySearch() {
    return !!this.filters.name && this.filters.user_id === "all";
  }

  public get untitledCatalogName() {
    return "Untitled Catalog " + this._lastUntitledCatalogNumber;
  }

  constructor(private readonly _rootStore: RootStore) {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadCatalogList(filters?: GetCatalogsFilters, sortValues?: CatalogsSortType) {
    // Note: If you try to load catalog with filters, and they are the same with
    // already stored one -> do nothing
    if (filters && isEqual(filters, this.filters) && !sortValues) return;
    if (sortValues && isEqual(sortValues, this.sortValues) && !filters) return;
    if (this.loading && this._prevRequest) this._prevRequest.abort();

    runInAction(() => {
      this._loading = true;
      if (filters) this.setFilters(filters);
      if (sortValues) this.setSortValues(sortValues);
    });

    const request = await getCatalogs.getRequest({
      queryParams: { ...this._getRequestFilters(), ...this.sortValues },
    });
    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        this._setCatalogList(success);
      } else {
        this._totalCatalogsAmount = 0;
        this._filteredCatalogsAmount = 0;
        this._catalogsList = [];
      }

      this._prevRequest = null;
      this._loading = false;
    });
  }

  public async loadLastUntitledCatalogNumber() {
    const { success } = await getLastUntitledCatalogNumber.getRequest().fetch();

    runInAction(() => {
      if (success) {
        this._lastUntitledCatalogNumber = success;
      }
    });
  }

  public async createCatalog(catalogData: PostCreateCatalogData, filters?: FiltersType) {
    const parsedFilters = this.parseFilters(filters);

    runInAction(() => (this._loading = true));

    const { success, error, details } = await postCreateCatalog
      .getRequest({ data: { ...catalogData, filter: parsedFilters } })
      .fetch();

    runInAction(() => {
      if (success) {
        this._setCatalogList(success);

        getUserTagManagerInfoCallback((response) => {
          pushDataLayerEvent({
            event: "create_catalog",
            user_id: response.user_id,
            total: success.total_items,
            account_type: response.account_type,
            is_trial: response.isTrial,
          });
        });
      }
      this._loading = false;
    });

    if (error) return { error, details };
  }

  public async updateCatalog(catalogId: CatalogModel["id"], data: PostCreateCatalogData) {
    const { error, success, details } = await putUpdateCatalog
      .getRequest({
        params: { catalogId },
        data,
      })
      .fetch();

    if (success) {
      const responseCatalog = success.rows.find(({ id }) => id === catalogId);
      if (responseCatalog) this.updateCatalogLocal(catalogId, responseCatalog);
    }

    if (error) return { error, details };
  }

  public async deleteCatalog(catalog: ExtendedCatalogModel) {
    runInAction(() => {
      this.toggleCatalogList(catalog, "loading");
    });

    const { success } = await deleteCatalog
      .getRequest({
        params: { catalogId: catalog.id },
        queryParams: this._getRequestFilters(),
      })
      .fetch();

    runInAction(() => {
      if (success) this._setCatalogList(success);
      this.toggleCatalogList(catalog, "loading");
    });
  }

  /* Requests ↑ */

  /* Action Request ↓ */
  public actionView(catalog: ExtendedCatalogModel) {
    window.open(getCatalogLink(catalog?.link?.uuid), "_blank");
  }

  public async actionExportPDF(catalog: ExtendedCatalogModel) {
    const { productsStore, toastStore, attributesStore, notificationStore, localeStore } =
      useStores();

    const res = await productsStore.startExportProducts(
      {
        export_name: "gembox_export",
        catalog_id: catalog.id,
        format: ProductsExportType.pdf,
      },
      attributesStore.customAttributes,
    );

    if (res) {
      toastStore.open(
        <>
          <Loader className={rowStyles.loader} type="circle-loader" size="small" />
          <Typography tag="span" size="medium600" color="textSecondary">
            {localeStore.t('products.modals["export-all-products-modal"]["loader-title"]')} PDF...
          </Typography>
        </>,
        {
          appearance: "primary",
          position: "bottomLeft",
          className: rowStyles.toast,
        },
      );
    } else {
      notificationStore.open({
        title: localeStore.t('products.modals["export-all-products-modal"]["error-modal"].title'),
        message: localeStore.t(
          'products.modals["export-all-products-modal"]["error-modal"].message',
        ),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: "",
        icon: "crossRoundedFilled",
        onlyConfirm: true,
      });
    }
  }

  public actionShare(catalog: ExtendedCatalogModel) {
    const { modalsStore } = useStores();
    modalsStore.open("ShareModal", { type: "catalogListItem", data: catalog, tab: "shareLink" });
  }

  public actionDeleteCatalog(catalog: ExtendedCatalogModel) {
    const { notificationStore, localeStore } = useStores();

    notificationStore.open({
      title: localeStore.t('catalog["catalogs-list"]["delete-notification"].title'),
      confirmText: localeStore.t("common.buttons.delete"),
      cancelText: localeStore.t("common.buttons.cancel"),
      onOk: () => this.deleteCatalog(catalog),
    });
  }

  /* Action Request ↑ */

  /* UI State ↓ */
  public setFilters(filters: GetCatalogsFilters) {
    this._filters = filters;
  }

  public parseFilters(filters: FiltersType | undefined): CatalogFilters | undefined {
    let parsedFilters;

    if (filters) {
      parsedFilters = {} as CatalogFilters;

      for (const k in filters) {
        const key = k as keyof FiltersType;
        switch (key) {
          case "attributes":
            for (const attribute of filters.attributes) {
              if (attribute.type === "multiselect") {
                parsedFilters[attribute.name as string] = attribute.value?.toString().split(", ");
              } else if (attribute.type === "number") {
                const numberAttributeValues = attribute.value?.toString().split(", ") || [];
                parsedFilters[attribute.name as string] = {};
                parsedFilters[attribute.name as string].from = Number(numberAttributeValues[0]);
                parsedFilters[attribute.name as string].to = Number(numberAttributeValues[1]);
              } else {
                parsedFilters[attribute.name as string] = attribute.value;
              }
            }
            break;
          case "fromPrice":
            parsedFilters.price = {};
            parsedFilters.price.from = filters[key];
            break;
          case "toPrice":
            parsedFilters.price.to = filters[key];
            break;
          case "mediaType":
            parsedFilters.media_type = filters[key];
            break;
          case "quantity":
            parsedFilters.quantity = {};
            parsedFilters.quantity.from = filters.quantity[0];
            parsedFilters.quantity.to = filters.quantity[1];
            break;
          case "user_id":
            parsedFilters.creator = filters.user_id;
            break;
          default:
            parsedFilters[key] = filters[key];
        }
      }
    }
    return parsedFilters;
  }

  public setSortValues(sortValues: CatalogsSortType) {
    this._sortValues = sortValues;
  }

  public resetSortValues() {
    this._sortValues = this._initialSortValues;
  }

  public toggleCatalogList(
    catalogs: MaybeArray<ExtendedCatalogModel>,
    field: "selected" | "loading",
  ) {
    toArrayOptions(catalogs).forEach((catalog) => {
      catalog.extended[field] = !catalog.extended[field];
    });
  }

  public updateCatalogLinkPrivacyLocal(catalog: ExtendedCatalogModel, isPrivate: boolean) {
    catalog.link.isPrivate = isPrivate;
  }

  public updateCatalogLocal(
    catalogId: ExtendedCatalogModel["id"],
    data: ExtendedCatalogModel | CatalogModel,
  ) {
    const foundCatalog = this._catalogsList.find(({ id }) => id === catalogId);
    if (foundCatalog) Object.assign(foundCatalog, data);
  }
  /* UI State  ↑  */

  /* Helpers ↓ */
  private _getRequestFilters(): CatalogsFiltersQueryType {
    const filtersCopy: CatalogsFiltersQueryType = toJS(this._filters);

    if (filtersCopy.user_id === "all") filtersCopy.user_id = null;
    if (filtersCopy.filterBy === "all") filtersCopy.filterBy = null;

    return filtersCopy;
  }

  private _setCatalogList(response: PaginationList<CatalogModel>) {
    this._totalCatalogsAmount = response.total_items;
    this._filteredCatalogsAmount = response.filtered_items;
    this._catalogsList = response.rows.map((item) => {
      return extendObservable(item, {
        ...item,
        extended: { selected: false, loading: false },
      });
    });
  }

  /* Helpers ↑ */
}
