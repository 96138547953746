import React from "react";
import Dropzone from "react-dropzone";
import moment from "moment";

import "./index.scss";

const ImageDropzone = ({
  children,
  dropHandler,
  onError,
  shouldOpenDropzone,
  maxWidth,
  ...props
}) => {
  const dropzoneRef = React.useRef(null);

  const [image, setImage] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [progress, setProgress] = React.useState(0);

  const onDelete = React.useCallback(() => {
    setImage(null);
    setFile(null);
    setFileName("");
  }, []);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((item) => {
      const reader = new FileReader();

      reader.onprogress = (e) => {
        const value = Math.ceil((e.loaded * 100) / e.total);
        setProgress(value);
      };
      reader.onloadend = () => {
        const image = new Image();

        setImage(reader.result);
        image.src = reader.result;

        image.onload = () => {
          if (onError && image.width > maxWidth) {
            onDelete();
            onError({
              type: "MAX_WIDTH_EXCEEDED",
              message: `
                Image width is greater than max width: 
                Received width: ${image.width},
                Max width: ${maxWidth},
               `,
            });
          }
        };

        if (dropHandler) {
          const fileName = item.name.substr(0, item.name.lastIndexOf("."));
          const fileExt = item.name.substr(item.name.lastIndexOf(".") + 1);
          const modifiedFileName = fileName + "_" + moment().toISOString() + "." + fileExt;
          setFileName(modifiedFileName);

          dropHandler({
            file: item,
            name: modifiedFileName,
            extension: fileExt,
            b64: reader.result,
          });
        }
      };

      if (item) {
        setFile(item);
        reader.readAsDataURL(item);
      }
    });
  };

  React.useEffect(() => {
    if (shouldOpenDropzone && dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  }, [shouldOpenDropzone]);

  return (
    <div className="image-dropzone">
      <Dropzone onDrop={onDrop} noDragEventsBubbling ref={dropzoneRef} multiple={false} {...props}>
        {(params) => <>{children({ ...params, image, file, fileName, progress })}</>}
      </Dropzone>
    </div>
  );
};

export default ImageDropzone;
