import React from "react";
import { observer } from "mobx-react-lite";
import { SvgIcon, FormField, Button, Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { Link } from "../link";

import { ReactComponent as WhatsappSVG } from "src/external-ts/assets/images/whatsapp-grey.svg";
import { ReactComponent as PhoneSVG } from "src/external-ts/assets/images/phone-grey.svg";
import { ReactComponent as MailSVG } from "src/external-ts/assets/images/mail-grey.svg";

import styles from "./product-settings.module.css";

export const ProductSettings: React.FC = observer(() => {
  const { localeStore } = useStores();

  return (
    <div className={styles.container} data-cy="product-settings">
      <Heading className={styles.title} tag="h2" color="textSecondary">
        {localeStore.t('settings["share-settings"]["product-settings"].title')}
      </Heading>
      <div className={styles.descriptionContainer} data-cy="product-settings-text-section">
        <Heading className={styles.subTitle} tag="h4" color="textSecondary">
          {localeStore.t('settings["share-settings"]["product-settings"].subtitle')}
        </Heading>
        <Typography className={styles.description} size="small" color="textTertiary">
          {localeStore.t('settings["share-settings"]["product-settings"].description')}{" "}
          <Link url="https://support.picupmedia.com/creating-and-customizing-share-link" />
        </Typography>
      </div>
      <div className={styles.fieldContainer}>
        <Button appearance="success">
          <SvgIcon icon={WhatsappSVG} />
        </Button>

        <FormField
          className={styles.whatsappContainer}
          inputWrapperClassName={styles.whatsappFieldWrapper}
          name="contact_whatsup"
          appearance="primaryV2"
          label="WhatsApp"
          placeholder={localeStore.t(
            'settings["share-settings"]["product-settings"].fields.whatsapp.placeholder',
          )}
          type="number"
          disableError
          data-cy="product-settings-whatsapp"
        />
      </div>

      <div className={styles.fieldContainer}>
        <Button appearance="secondaryOutlined">
          <SvgIcon icon={PhoneSVG} />
        </Button>

        <FormField
          containerClassName={styles.phoneContainer}
          phoneFieldClassName={styles.phone}
          countrySelectClassName={styles.countryCode}
          type="phone"
          placeholder={localeStore.t(
            'settings["share-settings"]["product-settings"].fields.phone.placeholder',
          )}
          label={localeStore.t('settings["share-settings"]["product-settings"].fields.phone.label')}
          name="contact_phone"
          disableError
        />
      </div>

      <div className={styles.fieldContainer}>
        <Button appearance="secondaryOutlined">
          <SvgIcon icon={MailSVG} />
        </Button>
        <FormField
          className={styles.emailContainer}
          inputWrapperClassName={styles.emailWrapper}
          appearance="primaryV2"
          type="text"
          placeholder={localeStore.t(
            'settings["share-settings"]["product-settings"].fields.email.placeholder',
          )}
          label={localeStore.t('settings["share-settings"]["product-settings"].fields.email.label')}
          name="contact_email"
          disableError
        />
      </div>
    </div>
  );
});

export default ProductSettings;
