import { clsx } from "@gemlightbox/core-kit";
import React from "react";

import "./styles.css";

export interface AlertProps {
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  className?: string;
  right?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ title, subtitle, icon, className, right }) => {
  return (
    <div className={clsx("gem-alert", className)}>
      <div className="gem-alert__icon">{icon}</div>
      <div className="gem-alert__content">
        <div className="gem-alert__title">{title}</div>
        <div className="gem-alert__subtitle">{subtitle}</div>
      </div>
      <div className="gem-alert__right">{right}</div>
    </div>
  );
};

export default Alert;
