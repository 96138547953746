import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { FormikErrors, FormikProps } from "formik";
import {
  Button,
  clsx,
  Form,
  FormField,
  Checkbox,
  Heading,
  loadImage,
  Modal,
  SelectGroupOption,
  SvgIcon,
  Typography,
  useDidUpdate,
  Loader,
  download,
  QueryParams,
  queryStringify,
  useLocalStorage,
  useDidMount,
} from "@gemlightbox/core-kit";

import { MediaDownloadType, MediaType, ProductImageModel } from "src/models";
import { useStores } from "src/hooks";
import { pushDataLayerEvent, proFeatureTooltip } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import {
  ExtendedCatalogFullModel,
  ExtendedMediaModel,
  ExtendedProductModel,
  ModalExtendedType,
} from "src/store";
import { config } from "src/config/environment";
import { numberNormalizer } from "src/common/helpers/common.helpers";
import {
  downloadMediaFormatNames,
  downloadMediaFormatOptions,
  initialValues,
  downloadMediaLocalStorageEntity,
} from "./download-media-modal.constants";
import { DownloadMediaFormType } from "./download-media-modal.types";

import { ReactComponent as ImageSVG } from "src/external-ts/assets/images/navbar/media-page.grey.svg";
import { ReactComponent as FileSVG } from "src/external-ts/assets/images/file-grey.svg";
import styles from "./download-media-modal.module.css";

export type DownloadMediaModalProps = ModalExtendedType<{
  media: ExtendedMediaModel[] | ExtendedProductModel[] | ExtendedCatalogFullModel;
  type: "medias" | "products" | "catalogs";
  onClose?: VoidFunction;
}>;

export const DownloadMediaModal: React.FC<DownloadMediaModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const downloadMediaLocalStorage = useLocalStorage<DownloadMediaFormType | null>(
      downloadMediaLocalStorageEntity,
      { initialValue: null },
    );

    const { modalsStore, mediaStore, notificationStore, userStore, localeStore } = useStores();
    const { canDownloadWithConfig } = useLimits();

    const { media, type, onClose } = options;
    let medias: ExtendedMediaModel[] | ProductImageModel[] = [];
    if (type === "medias") medias = media as ExtendedMediaModel[];

    if (type === "products") {
      const temp = media as ExtendedProductModel[];
      medias = temp
        .map((product) => product.images)
        .reduce((imagesIds, currentArr) => imagesIds.concat(currentArr));
    }

    const [initialForm, setInitialForm] = useState(
      downloadMediaLocalStorage.storeValue || initialValues,
    );
    const [originalFormat, setOriginalFormat] = useState(
      downloadMediaLocalStorage.storeValue?.format || initialValues.format,
    );
    const [currentFormat, setCurrentFormat] = useState(
      downloadMediaLocalStorage.storeValue?.format || initialValues.format,
    );

    const [formInner, setFormInner] = useState<FormikProps<DownloadMediaFormType> | null>(null);
    const formValues = formInner?.values;

    const [loading, setLoading] = useState(true);
    const [isDisabled, setDisabled] = useState(false);

    const normalizeSize = (value: string) => {
      const normalized = numberNormalizer(value);
      return +normalized > 10000 ? 10000 : normalized;
    };

    useDidUpdate(
      async () => {
        let initialSizesMedia: ExtendedMediaModel | ProductImageModel | undefined;
        if (type === "medias") {
          const temp = medias as ExtendedMediaModel[];
          initialSizesMedia = temp.find(
            (m) => m.type === MediaType.image || m.type === MediaType.blink,
          );
        }

        if (type === "products") {
          const temp = medias as ProductImageModel[];
          initialSizesMedia = temp.find(
            (m) => m.type === MediaType.image || m.type === MediaType.blink,
          );
        }

        if (!initialSizesMedia) {
          throw new Error("No image found.");
        }

        let file: string | undefined;

        if (type === "medias") {
          const temp = initialSizesMedia as ExtendedMediaModel;
          file =
            temp.type === "blink" && temp?.cropFile?.original
              ? temp?.cropFile?.original
              : temp.file.original;
        }

        if (type === "products") {
          const temp = initialSizesMedia as ProductImageModel;
          file =
            temp.type === "blink" && temp.arData?.cropFile?.original
              ? temp.arData?.cropFile?.original
              : temp.file.original;
        }

        if (!file) {
          throw new Error("File url not found.");
        }

        const values = file.split(".");

        let originalFormat = downloadMediaLocalStorage.storeValue?.format;
        if (!originalFormat || !canDownloadWithConfig) originalFormat = values[values.length - 1];

        let image = null;
        image = await loadImage(file);
        const availableOptions = Object.keys(MediaDownloadType);
        const index = availableOptions.indexOf(originalFormat);

        let initialFormat: MediaDownloadType | string = initialValues.format;

        if (index !== -1) {
          initialFormat = downloadMediaLocalStorage.storeValue?.format || originalFormat;
        }

        setLoading(false);
        setOriginalFormat(originalFormat);
        setInitialForm({
          format: initialFormat,
          width:
            downloadMediaLocalStorage.storeValue?.width ??
            image?.naturalWidth ??
            initialValues.width,
          height:
            downloadMediaLocalStorage.storeValue?.height ??
            image?.naturalHeight ??
            initialValues.height,
        });
      },
      [media],
      true,
    );

    useDidUpdate(() => {
      if (downloadMediaLocalStorage.storeValue === null) return;
      downloadMediaLocalStorage.setStoreValue(formValues || null);
    });

    useDidMount(() => {
      if (medias.length > 100) {
        modalsStore.close("DownloadMediaModal");
        notificationStore.open({
          title: localeStore.t('media.modals["download-media-modal"].limit'),
          confirmText: "OK",
          cancelText: "",
          confirmAppearance: "secondary",
          icon: "exclamation",
          onlyConfirm: true,
        });
      }
    });

    const handleSaveDownloadSettings = (value: boolean) => {
      if (!formValues) return;
      if (value) downloadMediaLocalStorage.setStoreValue(formValues);
      else downloadMediaLocalStorage.setStoreValue(null);
    };

    const handleValidate = (values: DownloadMediaFormType) => {
      const { width, height, format } = values;

      if ((width && height) || format === "pdf") {
        setDisabled(false);
        return;
      }

      setDisabled(true);

      const newErrors: FormikErrors<DownloadMediaFormType> = {};

      if (!width) newErrors.width = localeStore.t('media.modals["download-media-modal"].error');
      if (!height) newErrors.height = localeStore.t('media.modals["download-media-modal"].error');

      return newErrors;
    };

    const handleSubmit = async (values: DownloadMediaFormType) => {
      const { width, height, format } = values;

      const queryParams: QueryParams = {
        width,
        height,
        type: format,
        imageIDS: medias.map(({ id }) => id),
        token: userStore.token,
        isUseSkuAsNames: true,
      };

      if (currentFormat === MediaDownloadType.pdf) {
        delete queryParams.width;
        delete queryParams.height;
      }

      const query = queryStringify(queryParams);
      download(config.apiUrl + "/api/content/download/resize" + query);
      pushDataLayerEvent({ event: "gemhub:media:download" });
      if (medias.length > 1 && type === "medias") {
        notificationStore.openLoader({
          loaderType: "circle-loader",
        });
        const res = await mediaStore.createMediaCollection(medias as ExtendedMediaModel[]);
        notificationStore.closeLoader();

        if (res) {
          modalsStore.open("DownloadModal", { data: res, type: "mediaCollection", onClose });
        }
      } else {
        modalsStore.open("DownloadModal", { data: media[0], type: "media", onClose });
      }

      setClose();
    };

    const checkIfOptionIsDisabled = useCallback(
      (value: string) => {
        const isSameFormat = value === originalFormat;
        const isJPG = value === MediaDownloadType.jpg;

        let result = !canDownloadWithConfig;

        if (isSameFormat || isJPG) result = false;

        return result;
      },
      [options.media, originalFormat],
    );

    const handleMouseEnter = (id: string) => {
      proFeatureTooltip(id, {
        position: "right",
        offsetX: 24,
        onUpgradeClick: setClose,
      });
    };

    const sizesDisabled = !canDownloadWithConfig || checkIfOptionIsDisabled(currentFormat);

    return (
      <Modal
        name="download-media-modal"
        data-cy="download-media-modal"
        contentClassName={styles.modalContent}
        setClose={setClose}
        isOpen={isOpen}
        withCross
        onFinalClosed={onFinalClosed}
        disableBorderRadius={true}
        disablePadding={true}
      >
        {loading && <Loader className={styles.loader} type="circle-loader" position="absolute" />}
        <Heading tag="h2" color="textSecondary">
          {localeStore.t('media.modals["download-media-modal"].title')}
        </Heading>

        <Form
          initialValues={initialForm}
          innerRef={setFormInner}
          validate={handleValidate}
          onSubmit={handleSubmit}
          className={styles.form}
          contentClassName={styles.formContent}
          enableReinitialize
          validateOnChange
        >
          <FormField
            inputWrapperClassName={styles.selectWrapper}
            className={styles.select}
            type="select-group"
            name="format"
            searchValue={
              formValues?.format
                ? downloadMediaFormatNames[formValues.format as MediaDownloadType]
                : ""
            }
            label={localeStore.t('media.modals["download-media-modal"]["file-type"]')}
            appearance="primaryV2"
            renderPrefix={() => (
              <SvgIcon
                className={styles.fileIcon}
                icon={currentFormat === MediaDownloadType.pdf ? FileSVG : ImageSVG}
              />
            )}
            onChange={setCurrentFormat}
            disableClear
            disableUncheck
            disableSearch
          >
            {downloadMediaFormatOptions.map(({ label, value, id }) => {
              const disabled = checkIfOptionIsDisabled(value);

              return (
                <SelectGroupOption
                  key={value}
                  className={clsx(styles.formatOption, { [styles.disabled]: disabled })}
                  contentContainerClassName={styles.formatOptionContent}
                  value={value}
                  onMouseEnter={disabled ? () => handleMouseEnter(id) : undefined}
                  disabled={disabled}
                >
                  <SvgIcon
                    className={styles.fileIcon}
                    icon={value === MediaDownloadType.pdf ? FileSVG : ImageSVG}
                  />
                  <Typography id={id} tag="span" color="textSecondary" size="extraSmall">
                    {label}
                  </Typography>
                </SelectGroupOption>
              );
            })}
          </FormField>
          <div
            className={clsx(styles.sizesContainer, {
              [styles.opened]: currentFormat !== MediaDownloadType.pdf,
              [styles.closed]: currentFormat === MediaDownloadType.pdf,
            })}
          >
            <Typography size="small600" color="textSecondary">
              {localeStore.t('media.modals["download-media-modal"]["select-size"]')}
            </Typography>
            <div className={styles.sizeInputs}>
              <FormField
                id="download-media-modal-width"
                type="text"
                name="width"
                suffix="px"
                appearance="primaryV2"
                label={localeStore.t('media.modals["download-media-modal"].width')}
                placeholder="0"
                className={styles.sizeWrapper}
                inputWrapperClassName={styles.sizeInput}
                error={(formInner?.errors.width as string) || ""}
                readOnly={sizesDisabled}
                forceAffix
                normalize={normalizeSize}
                onMouseEnter={
                  sizesDisabled ? () => handleMouseEnter("download-media-modal-width") : undefined
                }
              />
              <FormField
                id="download-media-modal-height"
                type="text"
                name="height"
                suffix="px"
                appearance="primaryV2"
                label={localeStore.t('media.modals["download-media-modal"].height')}
                placeholder="0"
                forceAffix
                className={styles.sizeWrapper}
                inputWrapperClassName={styles.sizeInput}
                error={(formInner?.errors.height as string) || ""}
                readOnly={sizesDisabled}
                normalize={normalizeSize}
                onMouseEnter={
                  sizesDisabled ? () => handleMouseEnter("download-media-modal-height") : undefined
                }
              />
            </div>
          </div>
          <Checkbox
            containerClassName={styles.checkboxContainer}
            label={localeStore.t('media.modals["download-media-modal"].checkbox')}
            checked={!!downloadMediaLocalStorage.storeValue}
            onChange={handleSaveDownloadSettings}
            disableError
          />
          <div className={styles.buttons}>
            <Button className={styles.button} appearance="tertiaryOutlined" onClick={setClose}>
              {localeStore.t('media.modals["download-media-modal"].buttons.cancel')}
            </Button>
            <Button className={styles.button} type="submit" disabled={isDisabled}>
              {localeStore.t('media.modals["download-media-modal"].buttons.download')}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  },
);
