import React from "react";
import { SvgIcon, clsx } from "@gemlightbox/core-kit";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";

import { ExtendedProductModel } from "src/store";

import styles from "./products-preview.module.css";

interface ProductsPreviewProps {
  totalAmount: number;
  view: "grid" | "table";
  productToShow: ExtendedProductModel[];
  className?: string;
}

export const ProductsPreview: React.FC<ProductsPreviewProps> = ({
  className,
  totalAmount,
  productToShow,
  view,
}) => {
  const previewStyles = clsx(styles.itemContainer, styles[view]);

  const remainingAmount = totalAmount - productToShow.length;
  return (
    <div className={className}>
      {productToShow.map((product) => {
        return (
          <div key={product._id} className={previewStyles}>
            {!product.images[0].file ? (
              <SvgIcon icon={PendingUpSVG} size={view === "grid" ? [40, 30] : [34, 26]} />
            ) : (
              <img src={product.images[0].file.small} className={styles.image} />
            )}
          </div>
        );
      })}
      {remainingAmount !== 0 && (
        <div className={clsx(previewStyles, styles.totalAmount)}>
          +{totalAmount - productToShow.length}
        </div>
      )}
    </div>
  );
};

export default ProductsPreview;
