import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Pagination, useDidMount, useDidUnmount, useDidUpdate } from "@gemlightbox/core-kit";

import { PageHeader, PageContainer, ViewTypes } from "src/external-ts/components";
import { usePageViewEvent, useStores } from "src/hooks";
import { ErrorCodes } from "src/constants";
import WSConnection from "src/common/socket";
import { ProductsListPlaceholder } from "./products-list/products-list-placeholder";
import { ProductsList } from "./products-list";
import { ProductsControlPanel } from "./products-control-panel";
import { ProductsSelectedFiltersPanel } from "./products-selected-filters-panel";
import { ProductsSelectionPanel } from "./products-selection-panel";
import CloudStorageFullAlert from "src/components/CloudStorageFullAlert";

export const Products: React.FC = observer(() => {
  usePageViewEvent();

  const productsContainerRef = useRef<HTMLDivElement>(null);

  const { productsStore, chowTaiFookStore, mediaStore, localeStore, userStore } = useStores();

  //load products on mount
  useDidMount(() => productsStore.loadProductsList());

  useDidUpdate(
    () => {
      //connect to products sockets
      const productsWS = new WSConnection("/product", userStore.token);
      productsWS.on("product/PRODUCT_RELOAD", ({ payload: { updatedIDs } }: any) => {
        const idsWithoutMedia = productsStore.productsWithoutMedia.map((product) => product._id);
        if (updatedIDs?.every((id: number) => idsWithoutMedia.includes(id))) return;
        productsStore.loadProductsList();
      });
      return () => productsWS.destroy();
    },
    [userStore.token],
    true,
  );

  useDidMount(async () => {
    //if is chow tai fook user -> install ctf directory
    if (userStore.isCTF) {
      const error = await chowTaiFookStore.loadCTFStatus();
      if (error?.originalError?.code === ErrorCodes.CTF_MISSING_TOKEN) {
        chowTaiFookStore.installCTFDirectory(userStore.userMe?.user?.email || "");
      }
    }
  });

  useDidUnmount(() => {
    //reset store but keep the filters on unmount
    productsStore.resetStore({ keepFilters: true });
    mediaStore.resetStore();
  });

  const handlePageChange = async (page: number) => {
    await productsStore.loadProductsList({ ...productsStore.filters, page });
    productsContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleLimitChange = (limit: number) => {
    productsStore.loadProductsList({ ...productsStore.filters, limit, page: 1 });
    productsContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  useDidUpdate(() => {
    if (productsStore.filters.isThereMedia === undefined) {
      productsStore.loadProductsList({ ...productsStore.filters, page: 1 });
    }
    productsContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  }, [productsStore.view]);

  const showPlaceholder =
    productsStore.loading ||
    (productsStore.view === ViewTypes.table && !productsStore.resultsProductsAmount) ||
    (productsStore.view === ViewTypes.grid &&
      ((productsStore.filters.isThereMedia === undefined &&
        !productsStore.productsWithoutMediaAmount &&
        !productsStore.resultsProductsAmount) ||
        (productsStore.filters.isThereMedia !== undefined &&
          !productsStore.resultsProductsAmount)));

  return (
    <PageContainer>
      <PageHeader title={localeStore.t("products.title")} showSubscriptionBadge />
      <CloudStorageFullAlert />
      <ProductsControlPanel />
      <ProductsSelectionPanel />
      <ProductsSelectedFiltersPanel />

      {showPlaceholder && <ProductsListPlaceholder />}
      {!showPlaceholder && (
        <>
          <ProductsList view={productsStore.view} productsContainerRef={productsContainerRef} />
          <Pagination
            totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
            prevText={localeStore.t("components.ui.pagination.prev")}
            nextText={localeStore.t("components.ui.pagination.next")}
            perPageText={localeStore.t('components.ui.pagination["per-page"]')}
            page={productsStore.filters.page}
            totalPages={productsStore.totalPages}
            totalItems={productsStore.resultsProductsAmount}
            limit={productsStore.filters.limit}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
            data-cy="products-pagination"
          />
        </>
      )}
    </PageContainer>
  );
});
