import { getCountryCallingCode, isPossiblePhoneNumber } from "@gemlightbox/core-kit";

import { validateEmail } from "src/utils";
import { ShareSettingsFormErrorsType, ShareSettingsFormType } from "./share-settings.types";

export const shareSettingsValidation = (values: ShareSettingsFormType) => {
  const errors: ShareSettingsFormErrorsType = {};

  const companyCallingCode = getCountryCallingCode(values.company_phone.country).callingCode;
  const companyInternationalPhone = `+${companyCallingCode}${values.company_phone.phone}`;

  const contactCallingCode = getCountryCallingCode(values.contact_phone.country).callingCode;
  const contactInternationalPhone = `+${contactCallingCode}${values.contact_phone.phone}`;

  if (!isPossiblePhoneNumber(companyInternationalPhone) && values.company_phone.phone !== "") {
    errors.company_phone = "Phone number is not correct";
  }

  if (!isPossiblePhoneNumber(contactInternationalPhone) && values.contact_phone.phone !== "") {
    errors.contact_phone = "Phone number is not correct";
  }

  if (!validateEmail(values.company_email) && values.company_email !== "") {
    errors.company_email = "Email is not correct";
  }

  if (!validateEmail(values.contact_email) && values.contact_email !== "") {
    errors.contact_email = "Email is not correct";
  }

  return errors;
};
