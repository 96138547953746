import React from "react";
import { clsx } from "@gemlightbox/core-kit";

import angleDownImg from "src/common/images/angle-down-black.svg";
import { withErrorBoundary } from "src/components/ErrorBoundary";

import "./index.scss";

const ExpandableLine = ({ title, children, className }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={clsx("expandable-line", { expanded: expanded, [`${className}`]: className })}>
      <div className="expandable-line__header" onClick={() => setExpanded(!expanded)}>
        <div className="title"> {title} </div>
        <div className="expand-icon">
          <img src={angleDownImg} alt="" />
        </div>
      </div>

      <div className="expandable-line__body">{children}</div>
    </div>
  );
};

export default withErrorBoundary(ExpandableLine);
