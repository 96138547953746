import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  ExpandableSidebar,
  ObjectType,
  useDidMount,
  clsx,
  useInfinityScroll,
  useMediaBreakpoints,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./pending-upload-media-sidebar.module.css";

export type PendingUploadMediaSidebarProps = {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: ObjectType;
};

export const PendingUploadMediaSidebar: React.FC<PendingUploadMediaSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { mediaStore, localeStore } = useStores();

    const { isMobileMedia } = useMediaBreakpoints();

    useDidMount(async () => {
      await mediaStore.loadMediaPendingUploadGroupList();
    });

    return (
      <ExpandableSidebar
        title={localeStore.t('products["products-list"]["pending-upload"].title')}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        sidebarContentClassName={styles.sidebarContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
      >
        <div
          className={clsx(styles.listContainer, globalStyles.addScrollStyles, {
            [styles.mobileMedia]: isMobileMedia,
          })}
        >
          <div className={styles.listWrapper}>
            {mediaStore.categoriesList.map((data) => (
              <div key={data.item_name} className={styles.listItem}>
                <div className={styles.imageContainer}>
                  <SvgIcon icon={PendingUpSVG} size={[34, 26]} />
                </div>
                <div className={styles.infoContainer}>
                  <span className={styles.deviceName}>
                    {localeStore
                      .t('media.modals["pending-upload-media-sidebar"]["pending-up-from"]')
                      .replace(
                        localeStore.t(
                          'media.modals["pending-upload-media-sidebar"]["unknown-source"]',
                        ),
                        data?.item_details?.deviceName ||
                          localeStore.t(
                            'media.modals["pending-upload-media-sidebar"]["unknown-source"]',
                          ),
                      )}
                  </span>
                  <span className={styles.mediaAmount}>
                    {data?.total_items}{" "}
                    {localeStore.t('media.modals["pending-upload-media-sidebar"].media')}{" "}
                    {data.extended.redundant && (
                      <span
                        className={styles.serialNumber}
                        title={data?.item_details?.serialNumber}
                      >
                        {localeStore.t(
                          'media.modals["pending-upload-media-sidebar"]["serial-number"]',
                        )}
                        : {data?.item_details?.serialNumber}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ExpandableSidebar>
    );
  },
);
