import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { observer } from "mobx-react-lite";
import { clsx, useDidMount, globalStyles } from "@gemlightbox/core-kit";

import AnimationModal from "src/components/modals/AnimationModal";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { INTEGRATION_STATUSES } from "src/constants";
import WSConnection from "src/common/socket";
import { useStores } from "src/hooks";
import { loadShopifySettings, silentChangeShopifyStatus } from "src/redux/reducers/shopify";
import ShopifySetupModal from "./modals/ShopifySetupModal";
import ShopifyDisconnectModal from "./modals/ShopifyDisconnectModal";
import ShopifyStatusBar from "./Shopify/ShopifyStatusBar";
import ShopifyCancelSyncModal from "./modals/ShopifyCancelSyncModal";
import ShopifyErrorsModal from "./modals/ShopifyErrorsModal";
import ShopifySettings from "./Shopify/ShopifySettings";
// import { EmbedWidget } from "./embed-widget";
// import { Paypal } from "./paypal";
import { Rapnet } from "./rapnet";
import { Stripe } from "./stripe";

import styles from "./integrations.module.css";
import { store } from "src/redux/store";

export type IntegrationsProps = {
  name?: string;
};

export const Integrations: React.FC<IntegrationsProps> = observer(({ name }) => {
  const dispatch = useDispatch();

  // const [clicks, setClicks] = useState(0);
  // const handleTemporaryClick = () => {
  //   setClicks((prevState) => prevState + 1);
  // };

  const { userStore } = useStores();

  const shopifyData = useSelector(({ shopifyReducer }: any) => shopifyReducer);
  const activeShopifyShop = useSelector(({ userReducer }: any) => userReducer.activeShopifyShop);

  const isShopify =
    name === getSettingsTab(SettingTabNames.integrations)?.item.shopify?.settings.name;

  useDidMount(() => {
    const syncConnection = new WSConnection("/sync", userStore.token);

    syncConnection.on("sync/SHOPIFY", ({ payload }: any) => {
      payload.batchStatus && dispatch(silentChangeShopifyStatus(payload.batchStatus));
    });

    return () => {
      if (syncConnection && !syncConnection.isClosed) syncConnection.destroy();
    };
  });

  useDidMount(async () => {
    if (!shopifyData.settingsList.length) await dispatch(loadShopifySettings());
  });

  const getShopifyStatus = () => {
    let result = shopifyData.syncStatus;

    if (
      !activeShopifyShop ||
      !shopifyData.settingsList.find((item: any) => item.shop === activeShopifyShop)
    ) {
      result = INTEGRATION_STATUSES.SETUP;
    }

    if (
      !shopifyData.syncStatus &&
      activeShopifyShop &&
      shopifyData.settingsList.find((item: any) => item.shop === activeShopifyShop)
    ) {
      result = INTEGRATION_STATUSES.DEFAULT;
    }

    return result;
  };

  return (
    <div className={clsx(styles.integrationsContainer, globalStyles.addScrollStyles)}>
      {/* <EmbedWidget onTemporaryClick={handleTemporaryClick} /> */}

      {!name && <ShopifyStatusBar status={getShopifyStatus()} />}

      <Rapnet />

      <Stripe />

      {/* {clicks >= 7 && (
        <>
          <Paypal />
        </>
      )} */}

      {isShopify && <ShopifySettings />}

      <AnimationModal
        component={<ShopifySetupModal />}
        isOpened={shopifyData.modals.setup.isOpened}
      />
      <AnimationModal
        component={<ShopifyDisconnectModal />}
        isOpened={shopifyData.modals.disconnect.isOpened}
      />
      <AnimationModal
        component={<ShopifyCancelSyncModal />}
        isOpened={shopifyData.modals.cancelSync.isOpened}
      />
      <AnimationModal
        component={<ShopifyErrorsModal />}
        isOpened={shopifyData.modals.errors.isOpened}
      />
    </div>
  );
});

export default Integrations;
