import React from "react";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Typography, UseBooleanReturnType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaType } from "src/models";
import { maxImagesPerBatch } from "../../media.constants";

import { ReactComponent as DustRemoveSVG } from "src/external-ts/assets/images/edit-media/dust-remove-icon.svg";

type DustRemoveButtonProps = {
  visible: UseBooleanReturnType;
  contentSet: React.Dispatch<React.SetStateAction<string>>;
};

export const DustRemoveButton: React.FC<DustRemoveButtonProps> = observer(
  ({ visible, contentSet }) => {
    const { mediaStore, modalsStore, tooltipStore, localeStore, subscriptionsStore, userStore } =
      useStores();

    const isMoreThanMaxImagesPerBatch =
      mediaStore.selectedMediaAmount > maxImagesPerBatch || mediaStore.isPendingUploedMediaSelected;

    const hasOnlyImages = mediaStore.selectedMediaList.every(
      (media) => media.type === MediaType.image,
    );

    const isDisabled = !hasOnlyImages || isMoreThanMaxImagesPerBatch;

    const handlePointerEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!hasOnlyImages) {
        tooltipStore.open(
          <Typography size="small">
            {localeStore.t(
              'media["media-selection-panel"]["dust-removal-button"]["only-images-tooltip"].title',
            )}
          </Typography>,
          {
            position: "bottom",
            appearance: "secondary",
            target: e.currentTarget,
            withAngle: true,
          },
        );

        return;
      }
    };

    const handlePointerLeave = () => {
      if (hasOnlyImages) return;
      tooltipStore.close();
    };

    const handleRemoveBackground = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countDustRemoval ?? 0) >= subscriptionsStore.limits.count_remove_dust
      ) {
        contentSet(localeStore.t('common["pro-user"]["remove-dust-upgrade-text"]'));
        visible.setTruthy();
        return;
      }

      modalsStore.open("BatchDustRemovalModal", {
        medias: mediaStore.selectedMediaList,
      });
      mediaStore.unselectAllMediaList("selected");
    };

    return (
      <Button
        appearance="primaryGhost"
        onClick={handleRemoveBackground}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        disabled={isDisabled}
        data-cy="dust-removal"
      >
        <SvgIcon icon={DustRemoveSVG} />
        {localeStore.t(
          'media["media-selection-panel"]["dust-removal-button"].buttons["dust-removal"]',
        )}
      </Button>
    );
  },
);

export default DustRemoveButton;
