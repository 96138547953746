import React, { memo } from "react";
import { SortableHandle } from "react-sortable-hoc";
import { clsx, Image, SvgIcon, Button } from "@gemlightbox/core-kit";

import { PendingUploadStatus, ProductImageModel } from "src/models";
import { getMediaSrc } from "src/utils";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as DragSVG } from "src/external-ts/assets/images/drag-grey.svg";
import { ReactComponent as PlaySVG } from "src/external-ts/assets/images/play-grey.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import styles from "./product-preview-media.module.css";

export type ProductPreviewMediaProps = {
  productImage: ProductImageModel;
  onDeleteClick: (media: ProductImageModel) => void;
  onMediaClick: (media: ProductImageModel) => void;
};

const DragHandle = SortableHandle(() => {
  return (
    <Button className={clsx(styles.dragIcon, styles.icon)} appearance="secondaryGhost">
      <SvgIcon icon={DragSVG} size={16} />
    </Button>
  );
});

export const ProductPreviewMedia: React.FC<ProductPreviewMediaProps> = memo(
  ({ productImage, onDeleteClick, onMediaClick }: ProductPreviewMediaProps) => {
    const isUploading = productImage?.status === PendingUploadStatus.pending;
    const isVideo = productImage.type === "video" || productImage.type === "video360";
    const isPdf = productImage.type === "pdf" && !isUploading;

    const src = getMediaSrc(productImage, "small");

    const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onDeleteClick(productImage);
    };

    const handleMediaClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onMediaClick(productImage);
    };

    return (
      <div
        data-cy="product-preview-media"
        className={clsx(styles.productPreviewMediaContainer, { [styles.pdfIconContainer]: isPdf })}
        onClick={handleMediaClick}
      >
        {isUploading && !src && (
          <div className={styles.pendingUpWrapper}>
            <SvgIcon icon={PendingUpSVG} size={[34, 26]} />
          </div>
        )}

        {isPdf && <SvgIcon icon={PdfSVG} />}
        {!isPdf && <Image className={styles.productPreviewMedia} src={src} />}

        {isVideo && (
          <SvgIcon className={clsx(styles.playIcon, styles.icon)} icon={PlaySVG} size={16} />
        )}

        <Button
          className={clsx(styles.trashIcon, styles.icon)}
          appearance="errorGhost"
          onClick={handleDeleteClick}
        >
          <SvgIcon icon={TrashSVG} size={16} />
        </Button>

        <DragHandle />
      </div>
    );
  },
);

export default ProductPreviewMedia;
