import React, { useMemo, useRef } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import {
  Typography,
  Button,
  SvgIcon,
  Collapse,
  Tooltip,
  ActionsList,
  ActionOption,
  useCall,
  useBoolean,
  clsx,
  globalStyles,
  NotificationText,
} from "@gemlightbox/core-kit";

import { downloadVideo } from "src/utils";
import { postCreateGeneratedCatalog } from "src/api";
import { MoreButtonActions } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { AttributeModel } from "src/models";
import { ExtendedCatalogModel } from "src/store";
import { generateCatalog } from "../products.utils";

import ChowTaiFookIcon from "src/external-ts/assets/images/chow-tai-fook-logo.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as AddToCatalogSVG } from "src/external-ts/assets/images/plus-folder-grey.svg";
import { ReactComponent as EditAttributeSVG } from "src/external-ts/assets/images/dotted-square-grey.svg";
import { ReactComponent as ExportSVG } from "src/external-ts/assets/images/file-csv-grey.svg";
import { ReactComponent as DownloadSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/subscription/success-prompt.svg";
import styles from "./products-selection-panel.module.css";

export const ProductsSelectionPanel: React.FC = observer(() => {
  const {
    productsStore,
    attributesStore,
    modalsStore,
    notificationStore,
    localeStore,
    chowTaiFookStore,
    userStore,
  } = useStores();

  const isDownloadButtonDisabled = useMemo(() => {
    return !productsStore.selectedProductsList.filter((product) => product.images.length).length;
  }, [productsStore.selectedProductsList]);

  const attributes = attributesStore.attributes.filter(
    (attribute) =>
      attribute.displayName !== "Title" &&
      attribute.displayName !== "Description" &&
      attribute.displayName !== "SKU",
  );

  const selectedAmount = productsStore.selectedProductsAmount;

  const useGenerateCatalogCall = useCall(postCreateGeneratedCatalog);

  const editAttributeButtonRef = useRef<HTMLButtonElement>(null);

  const isEditAttributeDropdownOpen = useBoolean(false);

  const handleDelete = () => {
    return modalsStore.open("DeleteProductModal", { products: productsStore.selectedProductsList });
  };

  const handleAddToCatalog = () => {
    modalsStore.open("AddToCatalogSidebar", {
      type: "assign",
      title: localeStore.t('products["products-selection-panel"]["add-to-catalog"].title'),
      confirmText: localeStore.t(
        'products["products-selection-panel"]["add-to-catalog"]["confirm-text"]',
      ),
      products: toJS(productsStore.selectedProductsList),
    });
  };

  const handleDownload = () => {
    const isNotOnlyVideo = productsStore.selectedProductsList.some((product) => {
      return product.images.find((media) => media.type === "blink" || media.type === "image");
    });

    if (!isNotOnlyVideo) {
      const ids = productsStore.selectedProductsList.map((product) => product.images[0].id);
      downloadVideo(ids, productsStore.selectedProductsList[0]);
      handleUnSelectAll();
      return;
    }

    modalsStore.open("DownloadMediaModal", {
      media: productsStore.selectedProductsList,
      type: "products",
    });
    handleUnSelectAll();
  };

  const handleExport = () => {
    return modalsStore.open("ExportAllProductsModal", {
      ids: productsStore.selectedProductsList.map((product) => product._id),
    });
  };

  const handleChowTaiFook = async () => {
    notificationStore.openLoader({
      loaderType: "goo-loader",
      subtitle: localeStore.t('products["products-selection-panel"].ctf.loader.subtitle'),
      appearance: "secondary",
    });

    const { success, error } = await chowTaiFookStore.startCTFSync(
      productsStore.selectedProductsList,
    );

    notificationStore.closeLoader();

    if (success) {
      notificationStore.open({
        title: localeStore.t('settings.subscriptions["second-survey-success"].title'),
        message: (
          <NotificationText disableBold>
            {localeStore.t('settings.subscriptions["second-survey-success"].message')}
          </NotificationText>
        ),
        confirmText: localeStore.t('settings.subscriptions["second-survey-success"]["ok-text"]'),
        cancelText: "",
        onlyConfirm: true,
        confirmAppearance: "primary",
        icon: <SvgIcon icon={SuccessSVG} size={195} />,
      });
    } else {
      notificationStore.open({
        title: localeStore.t('products["products-selection-panel"].ctf["validation-error"].title'),
        message: error?.formattedMessage,
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: "",
        icon: "exclamation",
        confirmAppearance: "secondary",
        onlyConfirm: true,
      });
    }
  };

  const handleShare = async () => {
    if (productsStore.selectedProductsAmount === 1) {
      modalsStore.open("ShareModal", {
        data: productsStore.selectedProductsList[0],
        type: "product",
        tab: "shareLink",
      });
    } else {
      const generatedCatalogData = generateCatalog(toJS(productsStore.selectedProductsList));
      const response = await useGenerateCatalogCall.submit({ data: generatedCatalogData });
      const generatedCatalog = response.success as ExtendedCatalogModel;

      if (!generatedCatalog) return;

      modalsStore.open("ShareModal", {
        type: "catalogFullItem",
        data: generatedCatalog,
        tab: "shareLink",
      });
    }
  };

  const handleOptionClick = (attribute: AttributeModel) => () => {
    modalsStore.open("BulkAttributesEditModal", {
      attributeId: attribute.id,
    });
  };

  const handleSelectAll = () => productsStore.selectAllProductsList("selected");

  const handleUnSelectAll = () => productsStore.unselectAllProductsList("selected");

  return (
    <Collapse isOpen={productsStore.selectedProductsAmount > 0} opacityTransition>
      <div className={styles.productsSelectionPanelWrapper} data-cy="products-selection-panel">
        <div className={styles.productsSelectionPanelLeft}>
          <div className={styles.selectedAmount}>
            <Typography size="small600">
              {selectedAmount}&nbsp;
              {(selectedAmount > 1 &&
                localeStore.t(
                  'products["products-selection-panel"]["selected-amount"].multiple',
                )) ||
                localeStore.t('products["products-selection-panel"]["selected-amount"].single')}
            </Typography>
            &nbsp;
            <Typography size="small">
              {localeStore.t('products["products-selection-panel"].selected')}
            </Typography>
          </div>
          <Button
            appearance="secondary"
            size="small"
            onClick={handleUnSelectAll}
            data-cy="unselect-button"
          >
            {localeStore.t("common.buttons.unselect")}
          </Button>
          <Button
            appearance="primaryOutlined"
            size="small"
            onClick={handleSelectAll}
            data-cy="select-button"
          >
            {localeStore.t('common.buttons["select-all"]')}
          </Button>
        </div>

        <div className={styles.productsSelectionPanelRight}>
          <Button
            appearance="primaryGhost"
            onClick={handleShare}
            data-cy="products-panel-action-share"
          >
            <SvgIcon icon={ShareSVG} />
            {localeStore.t("common.buttons.share")}
          </Button>
          <Button
            appearance="primaryGhost"
            onClick={isEditAttributeDropdownOpen.trigger}
            forwardRef={editAttributeButtonRef}
            data-cy="edit-attribute"
          >
            <SvgIcon icon={EditAttributeSVG} />
            {localeStore.t('products["products-selection-panel"].buttons["edit-attribute"]')}
          </Button>
          <Tooltip
            className={styles.editAttributesTooltip}
            position="bottom"
            appearance="unset"
            target={editAttributeButtonRef}
            onClose={isEditAttributeDropdownOpen.setValue}
            isOpen={isEditAttributeDropdownOpen.value}
            disableAutoPosChange
          >
            <ActionsList
              className={clsx(styles.editAttributeActionsList, globalStyles.addScrollStyles)}
              onOptionClick={isEditAttributeDropdownOpen.setFalsy}
            >
              {attributes.map((attribute) => (
                <ActionOption
                  key={attribute.id}
                  className={styles.addToCatalogOption}
                  onClick={handleOptionClick(attribute)}
                >
                  {attribute.displayName || attribute.name}
                </ActionOption>
              ))}
            </ActionsList>
          </Tooltip>
          <Button
            className={styles.addToCatalogBtn}
            appearance="primaryGhost"
            onClick={handleAddToCatalog}
            data-cy="add-to-catalog"
          >
            <SvgIcon icon={AddToCatalogSVG} />
            {localeStore.t('products["products-selection-panel"].buttons["add-to-catalog"]')}
          </Button>
          {userStore.isCTF && (
            <Button appearance="secondaryGhost" onClick={handleChowTaiFook} data-cy="ChoTaiFook">
              <img src={ChowTaiFookIcon} />
              {localeStore.t('products["products-selection-panel"].buttons.ctf')}
            </Button>
          )}
          {!userStore.isCTF && (
            <Button
              className={styles.exportBtn}
              appearance="primaryGhost"
              onClick={handleExport}
              data-cy="export-products"
            >
              <SvgIcon icon={ExportSVG} />
              {localeStore.t("common.buttons.export")}
            </Button>
          )}

          <MoreButtonActions actionsContainerClassName={styles.actionsListContainer}>
            {!userStore.isCTF && (
              <ActionOption className={styles.exportOption} onClick={handleExport}>
                <SvgIcon icon={ExportSVG} />
                {localeStore.t("common.buttons.export")}
              </ActionOption>
            )}
            <ActionOption className={styles.addToCatalogOption} onClick={handleAddToCatalog}>
              <SvgIcon icon={AddToCatalogSVG} />
              {localeStore.t('products["products-selection-panel"].buttons["add-to-catalog"]')}
            </ActionOption>
            <ActionOption onClick={handleDownload} disabled={isDownloadButtonDisabled}>
              <SvgIcon icon={DownloadSVG} />
              {localeStore.t("common.buttons.download")}
            </ActionOption>
            <ActionOption onClick={handleDelete} data-cy="delete-selected">
              <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
              {localeStore.t("common.buttons.delete")}
            </ActionOption>
          </MoreButtonActions>
        </div>
      </div>
    </Collapse>
  );
});
