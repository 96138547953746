import React from "react";
import { useDidMount, useFetch } from "@gemlightbox/core-kit";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { PageContainer, PageHeader } from "src/external-ts/components";
import { AR_MEDIA_PAGE, CREATE_PAGE } from "src/constants";
import { useStores } from "src/hooks";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { ExtendedMediaModel } from "src/store";
import { getProduct } from "src/api";
import { MediaList } from "../media-list";
import { augmentedRealityMediaLimit } from "./augmented-reality.constants";

export const AugmentedRealityProduct: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();
  const { canBlinks } = useLimits();
  const { notificationStore, localeStore, productsStore, mediaStore } = useStores();
  useDidMount(() => {
    if (!productId) {
      return;
    }
    productsStore.loadProductsList();
  });

  const useProductFetchCallback = () => {
    if (!productId) {
      return null;
    }
    return useFetch(getProduct.setParams({ productId: productId as any }));
  };

  const useProductFetch = useProductFetchCallback();
  useProductFetch?.onFetchSuccess((data) => {
    mediaStore.loadMediaList({ type: "image", sku: data.title, page: 1, limit: 20 });
  });

  useProductFetch?.onFetchError(() => {
    notificationStore.open({
      title: localeStore.t('create["augmented-reality"].notification.error.title'),
      message: localeStore.t('create["augmented-reality"].notification.error.subtitle'),
      confirmText: localeStore.t('create["augmented-reality"].notification.error["ok-text"]'),
      cancelText: "",
      icon: "exclamation",
      confirmAppearance: "primary",
      onlyConfirm: true,
      onOk: () => window.location.reload(),
    });
  });

  const handleGoBack = () => navigate(-1);

  const handleAugmentedReality = async (medias: ExtendedMediaModel[]) => {
    const media = medias[0];
    if (!media) return;

    notificationStore.openLoader({
      loaderType: "diamond-loader",
      appearance: "secondary",
      title: localeStore.t('create["augmented-reality"].notification.loader.title'),
      subtitle: localeStore.t('create["augmented-reality"].notification.loader.subtitle'),
    });

    try {
      navigate(AR_MEDIA_PAGE.path, {
        state: {
          media: toJS(media),
          type: "media",
        },
      });
    } catch (error) {
      notificationStore.closeLoader();
      notificationStore.open({
        title: localeStore.t('create["augmented-reality"].notification.error.title'),
        message: localeStore.t('create["augmented-reality"].notification.error.subtitle'),
        confirmText: localeStore.t('create["augmented-reality"].notification.error["ok-text"]'),
        cancelText: "",
        icon: "exclamation",
        confirmAppearance: "primary",
        onlyConfirm: true,
        onOk: () => window.location.reload(),
      });
    }
  };
  // NOTE: canBlinks === false cause of delay. limits come after render
  if (canBlinks === false) return <Navigate to={CREATE_PAGE.path} />;
  return (
    <PageContainer>
      <PageHeader
        title={`${localeStore.t('create["augmented-reality"].neutralTitle')}`}
        onBack={handleGoBack}
      />
      <MediaList
        subTitle={`${localeStore.t('create["augmented-reality"].neutralSubtitle')}`}
        actionBtnText={localeStore.t('create["augmented-reality"]["action-btn-text"]')}
        type="image"
        productId={productId}
        limit={augmentedRealityMediaLimit}
        onActionClick={handleAugmentedReality}
      />
    </PageContainer>
  );
});

export default AugmentedRealityProduct;
