import React from "react";
import { observer } from "mobx-react-lite";

import Modal from "src/components/modals/Modal";
import OverlayLoader from "src/components/OverlayLoader";
import ErrorsList from "src/components/ErrorsList";
import IconCircle from "src/components/IconCircle";
import { useStores } from "src/hooks";

import checkmarkImg from "src/common/images/checkmark-primary.svg";

import "./index.scss";

export const IntegrationErrorsModal = observer(
  ({ isOpened, onClose, loading, totalItems, failedItems, errors }) => {
    const { localeStore } = useStores();

    return (
      <Modal
        isOpened={isOpened}
        className="integration-errors-modal"
        withClose
        handleModalClose={onClose}
      >
        {errors && Object.entries(errors).length > 0 ? (
          <>
            <div className="modal-content__header">
              <div className="headline">
                {localeStore.t('settings.integrations.modals["integration-errors-modal"].title')}
              </div>

              <div className="label error">
                {`${localeStore.t(
                  'settings.integrations.modals["integration-errors-modal"].description.first',
                )} ${failedItems} ${localeStore.t(
                  'settings.integrations.modals["integration-errors-modal"].description.of',
                )} ${totalItems} ${localeStore.t(
                  'settings.integrations.modals["integration-errors-modal"].description.last',
                )}`}
              </div>
            </div>

            <div className="modal-content__body secondary-scrollbar">
              <ErrorsList errors={errors} />
            </div>
          </>
        ) : (
          <>
            <div className="modal-content__header">
              <div className="headline">
                {localeStore.t(
                  'settings.integrations.modals["integration-errors-modal"]["sync-success"]',
                )}
              </div>
            </div>

            <div className="modal-content__body">
              <IconCircle img={checkmarkImg} />
            </div>
          </>
        )}

        {loading && <OverlayLoader />}
      </Modal>
    );
  },
);

export default IntegrationErrorsModal;
