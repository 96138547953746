import React from "react";
import { SvgIcon, clsx } from "@gemlightbox/core-kit";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as MediaSVG } from "src/external-ts/assets/images/navbar/media-page.grey.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import styles from "./media-preview.module.css";

export interface MediaPreviewProps {
  totalImages: number;
  src?: string;
  type?: "pdf" | "blink" | "video" | "image" | "video360";
  isUploading: boolean;
}

export const MediaPreview: React.FC<MediaPreviewProps> = ({
  src,
  totalImages,
  type,
  isUploading,
}) => {
  const shouldRenderCounter = totalImages > 1;
  const isPdf = type === "pdf";

  return (
    <div
      className={clsx(styles.container, {
        [styles.filled]: !src || isUploading,
      })}
    >
      {src && !isPdf && <img className={styles.img} src={src} alt="" />}
      {!src && !isPdf && !isUploading && <SvgIcon icon={MediaSVG} />}
      {isPdf && <SvgIcon icon={PdfSVG} />}
      {isUploading && <SvgIcon icon={PendingUpSVG} size={25} />}
      {shouldRenderCounter && <div className={styles.imageCounter}>{totalImages}</div>}
    </div>
  );
};
