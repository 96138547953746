import React from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { TableRow, TableCell, Typography } from "@gemlightbox/core-kit";

import { CatalogTypeTag, TableActionIcon } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { ExtendedCatalogModel } from "src/store/catalogs";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/eye-grey.svg";
import { ReactComponent as DownloadCloudSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as TrashSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as UserSVG } from "src/external-ts/assets/images/user.svg";
import styles from "./row.module.css";

type CatalogRowProps = {
  data: ExtendedCatalogModel;
  style?: React.CSSProperties;
};

export const Row: React.FC<CatalogRowProps> = observer(({ data, style }) => {
  const { catalogsStore, localeStore, userStore } = useStores();

  const navigate = useNavigate();

  const isSpecialCatalog = data.is_all || data.isRapnetCatalog;

  const handleRedirect = () => navigate(`/catalog/${data.id}`);

  const handleView = () => catalogsStore.actionView(data);

  const handleExportPDF = () => catalogsStore.actionExportPDF(data);

  const handleShare = () => catalogsStore.actionShare(data);

  const handleDelete = () => catalogsStore.actionDeleteCatalog(data);

  return (
    <TableRow
      className={styles.catalogRow}
      style={style}
      data-cy="catalog-table-row"
      data-name={data.name}
      onClick={handleRedirect}
    >
      <TableCell className={styles.name}>
        <div className={styles.nameWrapper}>
          <Typography
            className={globalStyles.applyMultiOverflow}
            size="small600"
            color="textSecondary"
          >
            {data.name}
          </Typography>
          <div className={styles.creator}>
            <Typography
              color={
                userStore.userMe?.user._id === data.created._id ? "textPrimary" : "textTertiary"
              }
              lineHeight={1}
            >
              <UserSVG style={{ verticalAlign: "bottom" }} />
            </Typography>
            <Typography
              className={globalStyles.applyMultiOverflow}
              size="extraSmall"
              color={
                userStore.userMe?.user._id === data.created._id ? "textPrimary" : "textTertiary"
              }
            >
              {data.created.first_name} {data.created.last_name}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell className={styles.totalProducts}>
        <Typography size="small" color="textSecondary">
          {data.total_products}
        </Typography>
      </TableCell>
      <TableCell className={styles.description}>
        <Typography className={globalStyles.applyMultiOverflow} size="small" color="textTertiary">
          {data.description}
        </Typography>
      </TableCell>
      <TableCell className={styles.type}>
        <Typography size="small">
          {!data.is_all && <CatalogTypeTag type={data.is_auto_catalog ? "automatic" : "manual"} />}
        </Typography>
      </TableCell>
      <TableCell className={styles.updatedAt}>
        <Typography size="small" color="textSecondary">
          {moment(data.updatedAt).format("MM/DD/YYYY")}
        </Typography>
      </TableCell>
      <TableCell className={styles.views}>
        <Typography size="small" color="textSecondary">
          {data.views}
        </Typography>
      </TableCell>
      <TableCell className={styles.actions}>
        <TableActionIcon
          appearance="primaryGhost"
          label={localeStore.t("common.buttons.view")}
          icon={EyeSVG}
          onClick={handleView}
        />
        <TableActionIcon
          appearance="primaryGhost"
          label={localeStore.t('common.buttons["export-pdf"]')}
          icon={DownloadCloudSVG}
          onClick={handleExportPDF}
          disabled={!data.total_products}
        />
        <TableActionIcon
          appearance="primaryGhost"
          label={localeStore.t("common.buttons.share")}
          icon={ShareSVG}
          onClick={handleShare}
        />
        {!isSpecialCatalog && (
          <TableActionIcon
            appearance="errorGhost"
            label={localeStore.t("common.buttons.delete")}
            icon={TrashSVG}
            onClick={handleDelete}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

export default Row;
