import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  ExpandableSidebar,
  ObjectType,
  useDidMount,
  clsx,
  useInfinityScroll,
  useMediaBreakpoints,
  SvgIcon,
  Checkbox,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ExtendedProductModel } from "src/store";
import { ReactComponent as DiamondSVG } from "src/containers/products/images/diamond-icon.svg";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./pending-upload-products-sidebar.module.css";
import { ProductsSelectionPanel } from "./products-selection-panel";

export type PendingUploadProductsSidebarProps = {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: ObjectType;
};

export const PendingUploadProductsSidebar: React.FC<PendingUploadProductsSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed }) => {
    const { productsStore, localeStore } = useStores();
    const navigate = useNavigate();

    const { isMobileMedia } = useMediaBreakpoints();
    let page = 1;
    const limit = 24;

    const useInfinityScrollHook = useInfinityScroll<HTMLDivElement>();
    useInfinityScrollHook.onScroll(() => {
      const offset = page * limit;
      if (offset < productsStore.totalProductsPendingUploadAmount) {
        useInfinityScrollHook.resetTrigger();
        page++;
        productsStore.loadProductsPendingUploadList(page, limit);
      }
    });

    useDidMount(async () => {
      await productsStore.loadProductsPendingUploadList(page, limit);
    });

    const handleEdit = (product: ExtendedProductModel) => {
      navigate(`/product/${product._id}/edit`);
      setClose();
    };

    const handleSelect = (list: ExtendedProductModel) =>
      productsStore.toggleProductsList(list, "selected");

    return (
      <ExpandableSidebar
        title={localeStore.t('products["products-list"]["pending-upload"].title')}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        sidebarContentClassName={styles.sidebarContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
      >
        <ProductsSelectionPanel
          beforeDelete={() => {
            useInfinityScrollHook.blockTrigger();
          }}
        />
        <div
          className={clsx(styles.listContainer, globalStyles.addScrollStyles, {
            [styles.mobileMedia]: isMobileMedia,
          })}
          ref={useInfinityScrollHook.ref}
        >
          <div className={styles.listWrapper}>
            {productsStore.productsPendingUploadList.map((data) => (
              <div
                key={data._id}
                className={clsx(styles.listItem, { [styles.selected]: data.extended.selected })}
                onClick={() => handleEdit(data)}
              >
                <Checkbox
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => handleSelect(data)}
                  checked={data.extended.selected}
                  disableError
                  data-cy="media-list-item-checkbox"
                />
                <div className={styles.imageContainer}>
                  {!data.images[0].file ? (
                    <SvgIcon icon={DiamondSVG} size={[24, 18]} />
                  ) : (
                    <img src={data.images[0].file.small} className={styles.image} />
                  )}
                </div>
                <span className={styles.title}>{data.title}</span>
              </div>
            ))}
          </div>
        </div>
      </ExpandableSidebar>
    );
  },
);
