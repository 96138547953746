import React, { useState } from "react";
import { useStores } from "src/hooks";
import { observer } from "mobx-react-lite";
import {
  useDidUpdate,
  Button,
  Heading,
  Info,
  Input,
  Modal,
  Select,
  SvgIcon,
  clsx,
} from "@gemlightbox/core-kit";
import { ModalExtendedType } from "src/store/modals";
import { cameraStore } from "src/external-ts/pages/camera/camera.store";
import { ReactComponent as ToolTipsSVG } from "src/external-ts/assets/images/camera/auto-photography-tips.svg";
import { ReactComponent as HardDriveSVG } from "src/external-ts/assets/images/camera/hard-drive-icon.svg";
import styles from "./local-save-settings-modal.module.css";
import { defaultMiniStorageSpace, storageSpaceUnit } from "../camera.constants";
import { StorageSpaceUnitKeys } from "../camera.types";
import { bytesToSize, sizeTobytes } from "src/utils/common.utils";
import { pushMediaLocalSaveLayerEvent } from "src/utils";
import { MuiSwitch } from "src/external-ts/components/mui-styled";

export type LocalSaveSettingsModalProps = ModalExtendedType<{
  onSuccess?: VoidFunction;
}>;

export const LocalSaveSettingsModal: React.FC<LocalSaveSettingsModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { appCallbackStore, localeStore, notificationStore } = useStores();
    const { onSuccess } = options;
    const [minSpac, setMinSpac] = useState(cameraStore.localSaveSettings.miniStorageSpace);
    const [unit, setUnit] = useState(cameraStore.localSaveSettings.storageSpaceUnit);
    const [errorSpaceSize, setErrorSpaceSize] = useState("");
    const [errorFolderPath, setErrorFolderPath] = useState("");
    const [driveInfo, setDriveInfo] = useState(cameraStore.driveInfo);
    const [useCustom, setUseCustom] = useState(cameraStore.localSaveSettings?.useCustom);

    const availableFreeSpace = bytesToSize(driveInfo?.availableFreeSpace).toFixed(2);
    const totalSize = bytesToSize(driveInfo?.totalSize).toFixed(2);

    const handleSelect = () => {
      appCallbackStore.selectFolder(
        (data) => {
          setDriveInfo(data);
          setErrorFolderPath("");
        },
        (msg, data) => {
          setDriveInfo(data);
          setErrorFolderPath(
            localeStore.t('camera["local-save-settings-modal"]["save-location"]["error-disk"]'),
          );
          console.warn(msg);
        },
      );
    };

    const handleSave = () => {
      if (driveInfo?.folderPath) {
        cameraStore.setLocalSaveSettings({
          ...(cameraStore.localSaveSettings || {}),
          folderPath: driveInfo?.folderPath,
          miniStorageSpace: minSpac,
          storageSpaceUnit: unit,
          useCustom: !!useCustom,
        });

        pushMediaLocalSaveLayerEvent("setConfigSuccess", {
          base_path: driveInfo?.folderPath,
          reserved_disk_space: minSpac,
          reserved_disk_space_unit: unit,
          use_custom_reserved_disk_space: !!useCustom,
        });

        onSuccess?.();
        setClose();
      }
    };

    const handleClose = async () => {
      if (
        !cameraStore.localSaveSettings?.folderPath ||
        (await cameraStore.isLocalStorageSettingsAvailable())
      ) {
        setClose();
      } else {
        notificationStore.open({
          title: localeStore.t('camera["local-save-settings-modal"]["cancel-svae"].title'),
          message: localeStore.t('camera["local-save-settings-modal"]["cancel-svae"].message'),
          confirmAppearance: "error",
          confirmText: localeStore.t('camera["local-save-settings-modal"]["cancel-svae"].exit'),
          cancelText: localeStore.t('camera["local-save-settings-modal"]["cancel-svae"].no'),
          onOk: () => {
            if (cameraStore.localSaveSettings.isLocalSaveMode)
              cameraStore.setIsLocalSaveMode(false);
            setClose();
          },
        });
      }
    };

    useDidUpdate(() => {
      if (driveInfo?.availableFreeSpace) {
        if (useCustom) {
          if (sizeTobytes(minSpac, unit) > driveInfo.availableFreeSpace) {
            setErrorSpaceSize(
              localeStore.t('camera["local-save-settings-modal"]["save-min-spac"].error'),
            );
          } else {
            setErrorSpaceSize("");
          }
        } else {
          if (
            sizeTobytes(defaultMiniStorageSpace.miniStorageSpace, defaultMiniStorageSpace.Unit) >
            driveInfo.availableFreeSpace
          ) {
            setErrorFolderPath(
              localeStore.t('camera["local-save-settings-modal"]["save-location"]["error-space"]'),
            );
          } else {
            setErrorFolderPath("");
            setErrorSpaceSize("");
          }
        }
      }
    }, [minSpac, unit, driveInfo, useCustom]);

    const onFinalOpened = () => {
      pushMediaLocalSaveLayerEvent("configDialogOpen");
      if (cameraStore.localSaveSettings?.folderPath)
        appCallbackStore.getDriveInfo(
          cameraStore.localSaveSettings.folderPath,
          (data) => {
            setDriveInfo(data);
          },
          () => {
            setErrorFolderPath(
              localeStore.t(
                'camera["local-save-settings-modal"]["save-location"]["error-location"]',
              ),
            );
          },
        );
    };

    return (
      <Modal
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        disableBackdropClose
        onFinalClosed={onFinalClosed}
        onFinalOpened={onFinalOpened}
        name="local-save-settings-modal"
        data-cy="local-save-settings-modal"
      >
        <div className={styles.header}>
          <Heading tag="h2" color="textSecondary">
            {localeStore.t('camera["local-save-settings-modal"].title')}
          </Heading>
        </div>
        <div className={styles.contentWrapper}>
          <div className={clsx(styles.controlContainer, { [styles.mb20]: errorFolderPath })}>
            <Input
              className={styles.input}
              label={localeStore.t('camera["local-save-settings-modal"]["save-location"].label')}
              appearance="primaryV2"
              placeholder={localeStore.t(
                'camera["local-save-settings-modal"]["save-location"].placeholder',
              )}
              type="text"
              value={driveInfo?.folderPath ?? cameraStore.localSaveSettings.folderPath}
              readOnly={true}
              error={errorFolderPath}
            ></Input>
            <Button
              appearance="primaryOutlined"
              className={styles.inputButton}
              onClick={() => handleSelect()}
            >
              {driveInfo?.folderPath
                ? localeStore.t('camera["local-save-settings-modal"].change')
                : localeStore.t('camera["local-save-settings-modal"].select')}
            </Button>
          </div>
          {driveInfo?.totalSize && !errorFolderPath && (
            <div className={styles.spaceWrapper}>
              <div className={styles.driveInfo}>
                <SvgIcon className={styles.deletemark} icon={HardDriveSVG} size={12} />
                <span>{driveInfo?.name}</span>
              </div>
              <span>
                {availableFreeSpace} GB{" "}
                {localeStore.t('camera["local-save-settings-modal"]["space-size"]')} {totalSize} GB
              </span>
            </div>
          )}
          <div className={styles.blockTitleContainer}>
            <div className={styles.blockTitle}>
              <span>
                {localeStore.t('camera["local-save-settings-modal"]["save-min-spac"].label')}
              </span>
              <Info
                toolTipClassName={styles.toolTipsContainer}
                appearance="secondary"
                position="top"
                withAngle
                icon={ToolTipsSVG}
                size={16}
              >
                {localeStore.t('camera["local-save-settings-modal"]["save-min-spac"].tips')}
              </Info>
            </div>
            <MuiSwitch
              className={styles.isCheck}
              checked={useCustom}
              onChange={(e) => setUseCustom(e.currentTarget.checked)}
            ></MuiSwitch>
          </div>
          {useCustom && (
            <div className={styles.controlContainer}>
              <Input
                className={styles.input}
                inputWrapperClassName={styles.inputWrapper}
                appearance="primaryV2"
                placeholder={localeStore.t(
                  'camera["local-save-settings-modal"]["save-min-spac"].placeholder',
                )}
                type="number"
                value={minSpac}
                onChange={(value) => setMinSpac(parseInt(value))}
                error={errorSpaceSize}
              >
                <Select
                  className={styles.select}
                  inputWrapperClassName={styles.selectInputWrapper}
                  appearance="primaryV2"
                  selectedOptionsKeys={unit}
                  options={Object.keys(storageSpaceUnit).map((opt) => ({
                    value: opt,
                    label: opt,
                  }))}
                  onChange={(opt) => setUnit(opt!.value as StorageSpaceUnitKeys)}
                  disableClearing
                />
              </Input>
            </div>
          )}
          <div className={styles.buttonContainerWrapper}>
            <div className={styles.buttonWrapper}>
              <Button
                appearance="secondaryOutlined"
                className={styles.itemBtn}
                onClick={handleClose}
              >
                {localeStore.t('camera["local-save-settings-modal"].cancel')}
              </Button>
              <Button
                appearance="primary"
                className={styles.itemBtn}
                onClick={handleSave}
                disabled={
                  (!minSpac && minSpac != 0) || !driveInfo || !!errorFolderPath || !!errorSpaceSize
                }
              >
                {localeStore.t('camera["local-save-settings-modal"].save')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default LocalSaveSettingsModal;
