import React from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";

import styles from "./link.module.css";

interface LinkProps {
  url: string;
  openInSameTab?: boolean;
}

export const Link: React.FC<LinkProps> = observer(({ url, openInSameTab }) => {
  const { localeStore } = useStores();

  return (
    <a
      className={styles.link}
      href={url}
      target={openInSameTab ? undefined : "_blank"}
      rel="noreferrer"
    >
      {localeStore.t('settings["share-settings"].link')}
    </a>
  );
});

export default Link;
