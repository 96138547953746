import React, { useMemo, useState, useRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  Button,
  Typography,
  Form,
  FormField,
  RadioOption,
  useFetch,
  useCall,
  useBoolean,
  parsePhoneNumber,
  getCountryCallingCode,
  useDidUpdate,
  useBlockRouting,
  clsx,
  NotificationTextSecondary,
  NotificationText,
} from "@gemlightbox/core-kit";

import { DefaultRedirect } from "src/components/default-redirect";
import { getGalleryInfo, GetGalleryInfoResponseType, putUpdateGalleryInfo } from "src/api";
import { useStores } from "src/hooks";
import { GlobalImageDataModel } from "src/models";
import { CompanyInfo } from "./company-info";
import { Link } from "./link";
import { LinkSettings } from "./link-settings";
import { ProductSettings } from "./product-settings";
import { initialValues, FORM_ID } from "./share-settings.constants";
import { ShareSettingsFormType } from "./share-settings.types";
import { shareSettingsValidation } from "./share-settings.utils";
import { LogoImageType } from "../logo";
import { getSettingsTab } from "../settings.utils";
import { SettingTabNames } from "../settings.constants";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./share-settings.module.css";

export const ShareSettings = observer(() => {
  const { localeStore, userStore, notificationStore } = useStores();

  const { onBlock, blockRouting, unblockRouting } = useBlockRouting();
  onBlock((blocker) => {
    notificationStore.open({
      title: localeStore.t('settings["share-settings"]["cancel-modal"].title'),
      message: (
        <>
          <NotificationTextSecondary>
            {localeStore.t('settings["share-settings"]["cancel-modal"].description.top')}
          </NotificationTextSecondary>
          <NotificationText>
            {localeStore.t('settings["share-settings"]["cancel-modal"].description.bottom')}
          </NotificationText>
        </>
      ),
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      confirmAppearance: "secondary",
      onOk: blocker.retry,
    });
  });

  const logoToSet = useRef<File | null>(null);

  const [currentLogo, setCurrentLogo] = useState<string | null>(null);
  const [formInner, setFormInner] = useState<FormikProps<any> | null>(null);
  const [logoHasChanged, setLogoHasChanged] = useState(false);
  const [resetLogo, setResetLogo] = useState(false);

  const showBuyButtonBoolean = useBoolean();

  const isSubmitButtonDisabled = !formInner?.dirty && !logoHasChanged;

  const { payload, actions } = useFetch(getGalleryInfo);

  const useUpdateGallery = useCall(putUpdateGalleryInfo);
  useUpdateGallery.onCallSuccess((data) => {
    notificationStore.open({
      title: localeStore.t('settings["share-settings"]["success-title"]'),
      confirmText: localeStore.t("common.buttons.confirm"),
      cancelText: "",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });

    actions.setData(data as GetGalleryInfoResponseType);
  });
  useUpdateGallery.onCallError((error) => {
    notificationStore.open({
      title: localeStore.t('settings["share-settings"]["error-modal"].title'),
      message: `${localeStore.t('settings["share-settings"]["error-modal"].description')}: ${
        error.formattedMessage
      }`,
      confirmText: localeStore.t("common.buttons.confirm"),
      cancelText: "",
      confirmAppearance: "primary",
      onlyConfirm: true,
    });
  });

  const { company_logo: companyLogo = {} as GlobalImageDataModel } = payload || {};

  useDidUpdate(() => {
    const logo = typeof companyLogo === "object" ? companyLogo?.small || null : companyLogo;
    setCurrentLogo(logo);
  }, [companyLogo]);

  useDidUpdate(() => {
    if (formInner?.dirty) blockRouting();
    else unblockRouting();
  }, [formInner?.dirty]);

  const shareSettingsInitialValues: ShareSettingsFormType = useMemo(() => {
    const parsedCompanyPhone = parsePhoneNumber(
      payload?.company_phone ?? initialValues.company_phone.phone,
    );
    const parsedContactPhone = parsePhoneNumber(
      payload?.contact_phone ?? initialValues.contact_phone.phone,
    );

    return {
      company_name: payload?.company_name ?? initialValues.company_name,
      company_phone: {
        country: parsedCompanyPhone?.country || "US",
        phone: parsedCompanyPhone?.nationalNumber || "",
      },
      company_email: payload?.company_email ?? initialValues.company_email,
      company_website: payload?.company_website ?? initialValues.company_website,
      company_description: payload?.company_description ?? initialValues.company_description,
      company_logo: payload?.company_logo ?? initialValues.company_logo,
      contact_whatsup: payload?.contact_whatsup ?? initialValues.contact_whatsup,
      contact_phone: {
        country: parsedContactPhone?.country || "US",
        phone: parsedContactPhone?.nationalNumber || "",
      },
      contact_email: payload?.contact_email ?? initialValues.contact_email,
      is_shown_similiar: payload?.is_shown_similiar ?? initialValues.is_shown_similiar,
      is_shown_gallery_info: payload?.is_shown_gallery_info ?? initialValues.is_shown_gallery_info,
      isShowBuyButtonOnShareLinks:
        payload?.isShowBuyButtonOnShareLinks ?? initialValues.isShowBuyButtonOnShareLinks,
      enable_download: payload?.enable_download ?? initialValues.enable_download,
      shareLinkAttributesState:
        payload?.shareLinkAttributesState ?? initialValues.shareLinkAttributesState,
    };
  }, [payload]);

  const handleCancel = () => {
    formInner?.resetForm();
    setResetLogo(true);
    setLogoHasChanged(false);
  };

  const handleLogoChange = (data: LogoImageType) => {
    logoToSet.current = data.file;
    setCurrentLogo(data.b64);
    setLogoHasChanged(true);
    setResetLogo(false);
  };

  const handleSubmit = (values: ShareSettingsFormType) => {
    const formData = new FormData();

    const companyPhone = values.company_phone.phone
      ? `+${getCountryCallingCode(values.company_phone.country).callingCode}${
          values.company_phone.phone
        }`
      : "";
    const contactPhone = values.contact_phone.phone
      ? `+${getCountryCallingCode(values.contact_phone.country).callingCode}${
          values.contact_phone.phone
        }`
      : "";

    Object.entries(values).forEach(([key, value]: [string, any]) => {
      if (key === "company_phone" || key === "contact_phone" || key === "company_logo") return;
      formData.append(key, value);
    });

    formData.set("company_phone", companyPhone);
    formData.set("contact_phone", contactPhone);

    if (logoHasChanged) {
      formData.set("company_logo", logoToSet.current as File);
    }

    useUpdateGallery.submit({ data: formData });
    setLogoHasChanged(false);
  };

  const handleBuyButtonMouseEnter = () => showBuyButtonBoolean.setTruthy();
  const handleBuyButtonMouseLeave = () => showBuyButtonBoolean.setFalsy();

  const descriptionLength: number = formInner?.values.company_description.length;

  const initialLogo =
    typeof shareSettingsInitialValues.company_logo === "object"
      ? shareSettingsInitialValues.company_logo?.small || null
      : shareSettingsInitialValues.company_logo;

  if (userStore.isSubaccount) return <DefaultRedirect />;

  return (
    <div className={styles.container}>
      <div className={clsx(styles.content, globalStyles.addScrollStyles)}>
        <Form<ShareSettingsFormType>
          formId={FORM_ID}
          initialValues={shareSettingsInitialValues}
          innerRef={setFormInner}
          onSubmit={handleSubmit}
          validate={shareSettingsValidation}
          enableReinitialize
        >
          <ProductSettings />
          <div className={styles.relatedProductsSettingContainer}>
            <Typography
              size="small600"
              color="textSecondary"
              data-cy="product-settings-related-title"
            >
              {localeStore.t('settings["share-settings"]["related-products"].title')}
            </Typography>
            <Typography
              size="extraSmall"
              color="textTertiary"
              data-cy="product-settings-related-subtitle"
            >
              {localeStore.t('settings["share-settings"]["related-products"].subtitle')}
            </Typography>
            <FormField
              className={styles.radioGroup}
              type="radio"
              name="is_shown_similiar"
              disabled={userStore.isSubaccount}
            >
              <RadioOption value={true}>
                {localeStore.t('settings["share-settings"]["radio-options"].yes')}
              </RadioOption>
              <RadioOption value={false}>
                {localeStore.t('settings["share-settings"]["radio-options"].no')}
              </RadioOption>
            </FormField>
          </div>
          <div
            className={styles.showBuyButtonContainer}
            onMouseEnter={handleBuyButtonMouseEnter}
            onMouseLeave={handleBuyButtonMouseLeave}
          >
            <Typography size="small600" color="textSecondary">
              {localeStore.t('settings["share-settings"]["show-buy-button"].title')}
            </Typography>
            <Typography
              className={styles.showBuyButtonDescription}
              size="extraSmall"
              color="textTertiary"
              data-cy="product-settings-related-subtitle"
            >
              {localeStore.t('settings["share-settings"]["show-buy-button"].description')}{" "}
              <Link
                url={getSettingsTab(SettingTabNames.integrations)?.item.path || ""}
                openInSameTab
              />
            </Typography>
            <FormField
              className={styles.radioGroup}
              type="radio"
              name="isShowBuyButtonOnShareLinks"
              disabled={!userStore.isPaypalUser && !userStore.isStripeUser}
            >
              <RadioOption value={true}>
                {localeStore.t('settings["share-settings"]["radio-options"].yes')}
              </RadioOption>
              <RadioOption value={false}>
                {localeStore.t('settings["share-settings"]["radio-options"].no')}
              </RadioOption>
              {!userStore.isPaypalUser && !userStore.isStripeUser && showBuyButtonBoolean.value && (
                <div className={styles.showBuyButtonTooltip}>
                  <Typography size="extraSmall" color="textSecondary">
                    {localeStore.t('settings["share-settings"]["show-buy-button"]["for-using"]')}{" "}
                    <ReactRouterLink
                      className={styles.showBuyButtonTooltipLink}
                      to={getSettingsTab(SettingTabNames.integrations)?.item.path || ""}
                    >
                      {localeStore.t('settings["share-settings"]["show-buy-button"].integrate')}
                    </ReactRouterLink>{" "}
                    {localeStore.t('settings["share-settings"]["show-buy-button"].first')}
                  </Typography>
                </div>
              )}
            </FormField>
          </div>
          <div className={styles.shareOptionContainerNoBorder}>
            <Typography
              className={styles.showDownloadButtonSettingTitle}
              size="small600"
              color="textSecondary"
            >
              {localeStore.t('settings["share-settings"]["attributes-visibility"].title')}
            </Typography>
            <Typography
              className={styles.showDownloadButtonSettingDescription}
              size="extraSmall"
              color="textTertiary"
            >
              {localeStore.t('settings["share-settings"]["attributes-visibility"]["first-row"]')}
              <br />
              {localeStore.t('settings["share-settings"]["attributes-visibility"]["second-row"]')}
            </Typography>
            <FormField className={styles.radioGroup} type="radio" name="shareLinkAttributesState">
              <RadioOption value="expanded">
                {localeStore.t('settings["share-settings"]["attribute-radio-options"].expanded')}
              </RadioOption>
              <RadioOption value="collapsed">
                {localeStore.t('settings["share-settings"]["attribute-radio-options"].collapsed')}
              </RadioOption>
            </FormField>
          </div>
          <LinkSettings
            resetLogo={resetLogo}
            initialLogo={initialLogo}
            logo={currentLogo}
            handleLogoChange={handleLogoChange}
          />
          <CompanyInfo descriptionLength={descriptionLength} />
          <div className={styles.shareOptionContainer} data-cy="logo-and-contact-info-section">
            <Typography
              className={styles.showLogoAndContactInfoSettingTitle}
              size="small600"
              color="textSecondary"
              data-cy="logo-and-contact-info-title"
            >
              {localeStore.t('settings["share-settings"]["logo-and-contact-info"].title')}
            </Typography>
            <Typography
              className={styles.showLogoAndContactInfoSettingDescription}
              size="extraSmall"
              color="textTertiary"
              data-cy="logo-and-contact-info-subtitle"
            >
              {localeStore.t('settings["share-settings"]["logo-and-contact-info"].subtitle')}{" "}
              <Link url="https://support.picupmedia.com/creating-and-customizing-share-link" />
            </Typography>
            <FormField
              className={styles.radioGroup}
              type="radio"
              name="is_shown_gallery_info"
              data-cy="logo-and-contact-info-radio"
            >
              <RadioOption value={true}>
                {localeStore.t('settings["share-settings"]["radio-options"].yes')}
              </RadioOption>
              <RadioOption value={false}>
                {localeStore.t('settings["share-settings"]["radio-options"].no')}
              </RadioOption>
            </FormField>
          </div>
          <div className={styles.shareOptionContainerNoBorder}>
            <Typography
              className={styles.showDownloadButtonSettingTitle}
              size="small600"
              color="textSecondary"
            >
              {localeStore.t('settings["share-settings"]["download-button-setting"].title')}
            </Typography>
            <Typography
              className={styles.showDownloadButtonSettingDescription}
              size="extraSmall"
              color="textTertiary"
            >
              {localeStore.t('settings["share-settings"]["download-button-setting"].subtitle')}{" "}
              <Link url="https://support.picupmedia.com/creating-and-customizing-share-link" />
            </Typography>
            <FormField className={styles.radioGroup} type="radio" name="enable_download">
              <RadioOption value={true}>
                {localeStore.t('settings["share-settings"]["radio-options"].yes')}
              </RadioOption>
              <RadioOption value={false}>
                {localeStore.t('settings["share-settings"]["radio-options"].no')}
              </RadioOption>
            </FormField>
          </div>
        </Form>
      </div>
      <div className={styles.submitButtonsContainer}>
        <Button
          appearance="secondaryOutlined"
          onClick={handleCancel}
          data-cy="share-settings-cancel-button"
        >
          {localeStore.t('settings["share-settings"].buttons.cancel')}
        </Button>
        <Button
          type="submit"
          form={FORM_ID}
          disabled={isSubmitButtonDisabled}
          data-cy="share-settings-save-button"
        >
          {localeStore.t('settings["share-settings"].buttons.save')}
        </Button>
      </div>
    </div>
  );
});

export default ShareSettings;
