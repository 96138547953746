import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { clsx, RefModel, curry, handleSetRef, useVirtualizedLoading } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { ExtendedMediaModel } from "src/store";
import { MediaItem } from "./media-item";
import { BlinkPromo } from "./blink-promo";
import {
  mediaMinItemWidth,
  mediaItemAddedHeight,
  mediaItemHeightMultiplier,
} from "../media.constants";
import { MediaPendingUploadCard } from "./media-pending-upload-card";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./media-list.module.css";

type MediaListProps = {
  containerRef: RefModel<HTMLDivElement>;
};

export const MediaList: React.FC<MediaListProps> = observer(({ containerRef }) => {
  const { mediaStore } = useStores();

  const { virtualizedItems, virtualizingContainerRef, virtualizingContentRef, onScroll } =
    useVirtualizedLoading({
      itemsAmount:
        mediaStore.mediaList.length + 1 + (mediaStore.totalMediaPendingUploadAmount > 0 ? 1 : 0), // NOTE: +1 due to BlinkPromo
      minItemWidth: mediaMinItemWidth,
      addedHeight: mediaItemAddedHeight,
      heightMultiplier: mediaItemHeightMultiplier,
    });

  const handleSelect = (list: ExtendedMediaModel) => mediaStore.toggleMediaList(list, "selected");

  useEffect(() => {
    if (mediaStore.scrollRestoration) {
      virtualizingContainerRef.current?.scrollTo(mediaStore.scrollPosition);
      mediaStore.setScrollRestoration(false);
    } else {
      mediaStore.setScrollPosition({ left: 0, top: 0 });
    }
  }, []);

  return (
    <div
      className={clsx(styles.mediaListContainer, globalStyles.addScrollStyles)}
      ref={curry(handleSetRef)([virtualizingContainerRef, containerRef])}
      onScroll={(e) => {
        mediaStore.setScrollPosition({
          left: (e.target as HTMLDivElement).scrollLeft,
          top: (e.target as HTMLDivElement).scrollTop,
        });
        onScroll();
      }}
      data-cy="media-list"
    >
      <div
        translate="no"
        className={styles.wrapper}
        ref={curry(handleSetRef)([virtualizingContentRef])}
      >
        {virtualizedItems.map(({ index, styles }) => {
          if (!index) return <BlinkPromo key="blink-promo" style={styles} />;

          if (mediaStore.totalMediaPendingUploadAmount > 0 && index === 1)
            return <MediaPendingUploadCard key="media-upload-card" style={styles} />;

          const mediaItem =
            mediaStore.mediaList[
              index - 1 - (mediaStore.totalMediaPendingUploadAmount > 0 ? 1 : 0)
            ];
          if (!mediaItem) return;

          return (
            <MediaItem
              key={mediaItem.id}
              item={mediaItem}
              style={styles}
              onSelect={handleSelect}
              selectOnClick={mediaStore.isMediaSelected}
            />
          );
        })}
      </div>
    </div>
  );
});
