import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { SubaccountModel } from "src/models";

import styles from "./pending-invitation.module.css";

interface PendingInvitationProps {
  data: SubaccountModel;
}

export const PendingInvitation: React.FC<PendingInvitationProps> = observer(({ data }) => {
  const { localeStore, notificationStore, subaccountsStore } = useStores();

  const handleCancel = () => {
    notificationStore.open({
      title: localeStore.t('settings.subaccounts["cancel-modal"].title'),
      confirmText: localeStore.t("common.buttons.yes"),
      cancelText: localeStore.t("common.buttons.no"),
      confirmAppearance: "secondary",
      onOk: () => subaccountsStore.deactivateInvitationLink(data.email),
    });
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.text} size="extraSmall">
        {localeStore.t(
          'settings.subaccounts["pending-invitations-list"]["pending-invitation"].label',
        )}
        <span className={styles.email}> {data.email}</span>
      </Typography>
      <Button
        className={styles.button}
        appearance="secondaryOutlined"
        size="small"
        onClick={handleCancel}
      >
        {localeStore.t(
          'settings.subaccounts["pending-invitations-list"]["pending-invitation"].buttons.cancel',
        )}
      </Button>
    </div>
  );
});
