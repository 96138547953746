import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, useWindowSize, SvgIcon } from "@gemlightbox/core-kit";

import { BREAKPOINTS } from "src/constants";
import { useStores } from "src/hooks";
import { CATALOG_PAGE } from "src/constants";

import { ReactComponent as PlusIcon } from "src/external-ts/assets/images/plus-thick-grey.svg";
import { ReactComponent as ShareIcon } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as ArrowLeftBack } from "src/external-ts/assets/images/arrow-left-oblong-grey.svg";
import styles from "./catalog-details-control-panel.module.css";

export const CatalogDetailsControlPanel: React.FC = observer(() => {
  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();
  const { catalogStore, modalsStore, localeStore } = useStores();

  const isAllCatalog = catalogStore.catalog?.is_all;

  const handleShare = () => {
    if (!catalogStore.catalog) return;

    return modalsStore.open("ShareModal", {
      type: "catalogFullItem",
      data: catalogStore.catalog,
      tab: "shareLink",
    });
  };

  const handleGoBack = () => navigate(CATALOG_PAGE.path);

  const handleToAddProducts = () => navigate(`/catalog/${catalogStore.catalog?.id}/add-product`);

  const backButtonText =
    windowWidth >= BREAKPOINTS.mobileL - 1
      ? localeStore.t('["catalog-details"]["catalog-details-control-panel"].buttons.back')
      : localeStore.t(
          '["catalog-details"]["catalog-details-control-panel"].buttons["back-mobile"]',
        );
  const addProductsButtonText =
    windowWidth >= BREAKPOINTS.mobileL - 1
      ? localeStore.t(
          '["catalog-details"]["catalog-details-control-panel"].buttons["add-products"]',
        )
      : "";
  const shareButtonText =
    windowWidth >= BREAKPOINTS.mobileL - 1
      ? localeStore.t('["catalog-details"]["catalog-details-control-panel"].buttons.share')
      : "";

  const disableBtn = catalogStore.loading || !catalogStore.catalog;

  return (
    <div className={styles.container}>
      <Button
        appearance="secondaryGhost"
        onClick={handleGoBack}
        data-cy="catalog-details-back-button"
      >
        <SvgIcon icon={ArrowLeftBack} />
        {backButtonText}
      </Button>

      <div className={styles.buttons}>
        {!isAllCatalog && !catalogStore.isAutoCatalog && (
          <Button
            appearance="primaryOutlined"
            onClick={handleToAddProducts}
            disabled={disableBtn}
            data-cy="catalog-details-add-product-button"
          >
            <SvgIcon icon={PlusIcon} />
            {addProductsButtonText}
          </Button>
        )}

        <Button onClick={handleShare} disabled={disableBtn} data-cy="catalog-details-share-button">
          <SvgIcon icon={ShareIcon} />
          {shareButtonText}
        </Button>
      </div>
    </div>
  );
});
