import React from "react";
import { observer } from "mobx-react-lite";
import { FormField, Typography, clsx } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { Link } from "../link";

import styles from "./company-info.module.css";

interface CompanyInfoProps {
  descriptionLength: number;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = observer(({ descriptionLength }) => {
  const { localeStore } = useStores();

  return (
    <div className={styles.container} data-cy="company-info">
      <div className={styles.descriptionContainer}>
        <Typography
          className={styles.title}
          size="small600"
          color="textSecondary"
          data-cy="company-title"
        >
          {localeStore.t('settings["share-settings"]["company-info"].title')}
        </Typography>
        <Typography size="extraSmall" color="textTertiary" data-cy="company-subtitle">
          {localeStore.t('settings["share-settings"]["company-info"].subtitle')}{" "}
          <Link
            url="https://support.picupmedia.com/creating-and-customizing-share-link"
            data-cy="company-learn-more"
          />
        </Typography>
      </div>
      <div className={styles.fieldsContainer}>
        <div className={styles.fieldsRow}>
          <FormField
            className={styles.field}
            inputWrapperClassName={styles.fieldWrapper}
            type="text"
            name="company_name"
            label={localeStore.t('settings["share-settings"]["company-info"].fields.name.label')}
            placeholder={localeStore.t(
              'settings["share-settings"]["company-info"].fields.name.placeholder',
            )}
            appearance="primaryV2"
            data-cy="company-info-name"
          />
          <FormField
            className={styles.field}
            inputWrapperClassName={styles.fieldWrapper}
            type="text"
            name="company_email"
            label={localeStore.t('settings["share-settings"]["company-info"].fields.email.label')}
            placeholder={localeStore.t(
              'settings["share-settings"]["company-info"].fields.email.placeholder',
            )}
            appearance="primaryV2"
            data-cy="company-info-email"
          />
        </div>
        <div className={styles.fieldsRow} data-cy="filed-with-phone">
          <FormField
            className={clsx(styles.field, styles.companyWebsiteField)}
            inputWrapperClassName={styles.fieldWrapper}
            type="text"
            name="company_website"
            label={localeStore.t('settings["share-settings"]["company-info"].fields.website.label')}
            placeholder="https://site.com"
            appearance="primaryV2"
            data-cy="company-info-website"
          />
          <FormField
            containerClassName={styles.phoneFieldWrapper}
            phoneFieldClassName={styles.phone}
            countrySelectClassName={styles.countryCode}
            type="phone"
            name="company_phone"
            label={localeStore.t('settings["share-settings"]["company-info"].fields.phone.label')}
            placeholder={localeStore.t(
              'settings["share-settings"]["company-info"].fields.phone.placeholder',
            )}
            data-cy="company-info-phone"
          />
        </div>
        <FormField
          inputWrapperClassName={clsx(styles.descriptionFieldWrapper, styles.fieldWrapper)}
          name="company_description"
          label={localeStore.t(
            'settings["share-settings"]["company-info"].fields.description.label',
          )}
          placeholder={localeStore.t(
            'settings["share-settings"]["company-info"].fields.description.placeholder',
          )}
          labelMessage={`${descriptionLength}/500`}
          appearance="primaryV2"
          type="textarea"
          maxLength={500}
          max="test"
          data-cy="company-info-description"
        />
      </div>
    </div>
  );
});

export default CompanyInfo;
