import React, { useState } from "react";
import {
  queryStringify,
  Select,
  SelectOption,
  useCall,
  useDebounce,
  useDidMount,
  Nullable,
} from "@gemlightbox/core-kit";

import { getProducts, postProduct } from "src/api";
import { useStores } from "src/hooks";

import styles from "../camera-preview-sidebar.module.css";

interface SKUSelectProps {
  selectedSKUOption: SelectOption<number> | undefined;
  onSKUChange: (value: SelectOption<number> | undefined) => void;
}

export const SKUSelect: React.FC<SKUSelectProps> = ({ selectedSKUOption, onSKUChange }) => {
  const { debounce } = useDebounce(100);
  const { localeStore, productsStore } = useStores();

  const [options, setOptions] = useState<SelectOption<number>[]>([]);
  const [search, setSearch] = useState<Nullable<string>>(null);
  const [isSearchUnique, setSearchUnique] = useState(false);
  const [creating, setCreating] = useState(false);

  const getProductsCall = useCall(getProducts.setQueryParams(queryStringify({ title: search })));
  getProductsCall.onCallSuccess(({ rows }) => {
    const options = rows.map(({ title, _id }) => ({
      label: title,
      value: _id,
    }));
    const doesExist = rows.find(({ title }) => title === search);

    setOptions(options);
    setSearchUnique(!doesExist);
  });

  const createProductCall = useCall(postProduct);
  createProductCall.onCallSuccess(async ({ rows, total_items }) => {
    productsStore.sendCreateProductEvent(total_items);

    await getProductsCall.submit();

    const product = rows[0];

    if (!search) return;

    handleChange({ label: search, value: product._id });
    setCreating(false);
  });

  const handleSearch = (value: string) => {
    setSearch(value);

    debounce(() => {
      getProductsCall.submit();
    });
  };

  const handleChange = (option: Nullable<SelectOption<number>> | undefined) => {
    if (option === undefined) return;

    if (option === null) {
      setSearch("");
      onSKUChange(undefined);
    }

    if (option !== null) {
      const { value, label } = option;
      if (!value || !label) return;
      setSearch(label);
      onSKUChange({ label, value });
    }
  };

  const handleCreateProduct = () => {
    if (!search) return;
    setCreating(true);
    createProductCall.submit({ data: { title: search } });
  };

  useDidMount(() => getProductsCall.submit());

  return (
    <>
      {creating && (
        <div className={styles.loadingBox}>
          <span className={styles.loadingPoint}></span>
          <span className={styles.loadingPoint}></span>
          <span className={styles.loadingPoint}></span>
        </div>
      )}
      <Select
        appearance="primaryV2"
        label="SKU"
        placeholder={
          creating
            ? ""
            : localeStore.t('camera["camera-preview-sidebar"]["sku-select"].placeholder')
        }
        createNewText={localeStore.t(
          'camera["camera-preview-sidebar"]["sku-select"]["create-new-text"]',
        )}
        selectedOptionsKeys={selectedSKUOption}
        options={options}
        onChange={handleChange}
        onSearch={handleSearch}
        onCreateNew={handleCreateProduct}
        canCreate={isSearchUnique}
        isTextEditable
        disableError
        data-hj-allow
        disabled={creating}
      />
    </>
  );
};
