import React from "react";
import { SvgIcon, clsx } from "@gemlightbox/core-kit";

import { ExtendedMediaModel } from "src/store";

import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import styles from "./media-preview.module.css";

interface MediaPreviewProps {
  totalMediaAmount: number;
  view: "grid" | "table";
  mediaToShow: ExtendedMediaModel[];
  className?: string;
  isPendingUpload?: boolean;
}

export const MediaPreview: React.FC<MediaPreviewProps> = ({
  className,
  totalMediaAmount,
  mediaToShow,
  view,
  isPendingUpload,
}) => {
  const mediaPreviewStyles = clsx(styles.itemContainer, styles[view]);

  const remainingMediaAmount = totalMediaAmount - mediaToShow.length;
  return (
    <div className={className}>
      {mediaToShow.map((media) => {
        if (media.type === "pdf") {
          return (
            <div key={media.id} className={mediaPreviewStyles}>
              <SvgIcon icon={PdfSVG} />
            </div>
          );
        }
        return (
          <div key={media.id} className={mediaPreviewStyles}>
            {!media.file ? (
              <SvgIcon icon={PendingUpSVG} size={view === "grid" ? [40, 30] : [34, 26]} />
            ) : (
              <img src={media?.file?.small} className={styles.image} />
            )}
          </div>
        );
      })}
      {!isPendingUpload && remainingMediaAmount !== 0 && (
        <div className={clsx(mediaPreviewStyles, styles.totalAmount)}>
          +{totalMediaAmount - mediaToShow.length}
        </div>
      )}
    </div>
  );
};

export default MediaPreview;
