import {
  INTEGRATION_ACTIONS,
  INTEGRATION_OPTIONS,
  INTEGRATION_STATUSES,
  INTEGRATIONS,
} from "src/constants";
import { useStores } from "src/hooks";

export const getShopifyStoreURL = (storeName) => `https://${storeName}.myshopify.com`;

export const getAvailableParams = (params) => {
  const extendedParams = params.extended ? Object.keys(params.extended) : [];

  return {
    standart: params.standart,
    extended: extendedParams,
    custom: filterCustomParams(params.custom, extendedParams),
  };
};

export const getSelectedParams = (aParams, sParams) => {
  const availableParams = getAvailableParams(aParams);

  // clear custom params for initial settings
  let result = {
    standart: availableParams.standart,
    extended: availableParams.extended,
    custom: [],
  };

  if (sParams) {
    const paramsAmount = Object.entries(sParams).reduce((acc, [_, values]) => {
      acc += values.length;
      return acc;
    }, 0);

    if (paramsAmount) {
      result = {
        standart: sParams.standart,
        extended: sParams.extended,
        custom: sParams.custom,
      };
    }
  }

  return result;
};

export const filterCustomParams = (customParams, extendedParams) =>
  customParams
    .filter((value) => value !== "productType")
    .filter((value) => !extendedParams.includes(value));

export const getIntegrationOptionsLocalized = () => {
  const { localeStore } = useStores();

  return INTEGRATION_OPTIONS.map(({ name, label }) => {
    switch (name) {
      case "Add Store":
        return { name, label: localeStore.t('common.helpers.integrations.options["add-store"]') };
      case "Select Store":
        return {
          name,
          label: localeStore.t('common.helpers.integrations.options["select-store"]'),
        };
      case "Settings":
        return { name, label: localeStore.t("common.helpers.integrations.options.settings") };
      case "Stop Syncing":
        return { name, label: localeStore.t('common.helpers.integrations.options["stop-sync"]') };
      case "Remove Integration":
        return {
          name,
          label: localeStore.t('common.helpers.integrations.options["remove-integration"]'),
        };
      case "View Errors":
        return { name, label: localeStore.t('common.helpers.integrations.options["view-errors"]') };
      default:
        return { name, label };
    }
  });
};

export const getIntegrationOptions = (status, integration) => {
  let result = getIntegrationOptionsLocalized();

  if (integration !== INTEGRATIONS.SHOPIFY) {
    result = result.filter(
      ({ name }) =>
        name !== INTEGRATION_ACTIONS.SELECT_STORE && name !== INTEGRATION_ACTIONS.ADD_STORE,
    );
  }

  if (status !== INTEGRATION_STATUSES.COMPLETED) {
    result = result.filter(({ name }) => name !== INTEGRATION_ACTIONS.VIEW_ERRORS);
  }

  if (status !== INTEGRATION_STATUSES.SYNCING) {
    result = result.filter(({ name }) => name !== INTEGRATION_ACTIONS.STOP_SYNC);
  }

  if (status === INTEGRATION_STATUSES.SYNCING) {
    result = result.filter(
      ({ name }) =>
        name !== INTEGRATION_ACTIONS.REMOVE_INTEGRATION &&
        !(integration === INTEGRATIONS.SHOPIFY && name === INTEGRATION_ACTIONS.SETTINGS),
    );
  }

  return result;
};
