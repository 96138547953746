import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDidMount } from "@gemlightbox/core-kit";

import IntegrationErrorsModal from "../IntegrationErrorsModal";

import {
  toggleShopifyErrorsModal,
  loadShopifyProducts,
  getShopifyFailedProductsErrors,
} from "src/redux/reducers/shopify";
import { getShopifyStoreURL } from "src/common/helpers/integrations.helpers";

export const ShopifyErrorsModal = ({ isOpened }) => {
  const dispatch = useDispatch();
  const { totalProductsAmount, failedProductsAmount, errors } = useSelector(
    ({ shopifyReducer }) => ({
      totalProductsAmount: shopifyReducer.totalProducts,
      failedProductsAmount: shopifyReducer.totalResults,
      errors: shopifyReducer.modals.errors.errors,
    }),
  );
  const activeShopifyShop = useSelector(({ userReducer }) => userReducer.activeShopifyShop);

  const [loading, setLoading] = React.useState(false);

  useDidMount(async () => {
    try {
      setLoading(true);
      await dispatch(
        loadShopifyProducts({
          limit: 50,
          syncStatus: "error",
          lastSyncOnly: true,
          shop_url: getShopifyStoreURL(activeShopifyShop),
        }),
      );
      dispatch(getShopifyFailedProductsErrors());
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  });

  return (
    <IntegrationErrorsModal
      isOpened={isOpened}
      onClose={() => dispatch(toggleShopifyErrorsModal())}
      loading={loading}
      totalItems={totalProductsAmount}
      failedItems={failedProductsAmount}
      errors={errors}
    />
  );
};

export default ShopifyErrorsModal;
