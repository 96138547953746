import { ApiBuilder, uniqId } from "@gemlightbox/core-kit";
import { config } from "src/config/environment";
import { MAINTENANCE_PAGE } from "src/constants";

type DashboardApiBuilderKeys = {
  isDisableCache: boolean;
  isRestoreCache: boolean;
};

export const dashboardApiBuilder = new ApiBuilder<any, DashboardApiBuilderKeys>({
  baseUrl: config.apiUrl,
  headers: () => {
    return {
      "index-response": "true",
      "x-request-id": uniqId(),
      "x-platform": window.$platform.isApp ? "MacApp" : "Web",
    };
  },
});

dashboardApiBuilder.setRequestInterceptors((keys, headers) => {
  if (keys.isDisableCache) {
    Object.assign(headers, { "cache-control": "private, no-cache, no-store" });
  }

  if (keys.isRestoreCache) {
    Object.assign(headers, { "cache-control": "private, no-cache, no-store, set-store" });
  }

  return headers;
});

dashboardApiBuilder.setResponseInterceptors((res) => {
  if (res.details.statusCode === 503) {
    if (
      !window.location.pathname
        .toLocaleLowerCase()
        .includes(MAINTENANCE_PAGE.path.toLocaleLowerCase())
    ) {
      window.location.replace(MAINTENANCE_PAGE.path);
    }
  }
  return res;
});

export const dashboardStaticApiBuilder = new ApiBuilder({
  baseUrl: config.staticURL,
});

export const dashboardSelfBuilder = new ApiBuilder({
  baseUrl: config.dashboardUrl,
});

export const dashboardApiMiddleware = dashboardApiBuilder.build();
export const dashboardStaticApiMiddleware = dashboardStaticApiBuilder.build();
export const dashboardSelfMiddleware = dashboardSelfBuilder.build();
