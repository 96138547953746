import React, { Fragment, useRef, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  SvgIcon,
  Button,
  Typography,
  Dropzone,
  Loader,
  useDidMount,
  useDidUnmount,
  FileUploadFullscreen,
  filteredMap,
  uniqId,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaType } from "src/models";
import { ExtendedMediaModel } from "src/store";
import { limitFeatureTooltip } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { MediaFilterTypeKeys } from "src/containers/media/media.types";
import {
  createPageMaxFiles,
  createPageMaxSizeInBytes,
  createPageUploaderAccept,
} from "../create.constants";
import { MediaItems } from "./media-items";

import { ReactComponent as UploadCloudSVG } from "src/external-ts/assets/images/upload-cloud-grey.svg";
import styles from "./media-list.module.css";

export type MediaListProps = {
  subTitle: string;
  actionBtnText: string;
  limit: number;
  onActionClick: (medias: ExtendedMediaModel[]) => void;
  productId?: string;
  type?: MediaFilterTypeKeys;
  withoutSku?: boolean;
  isRemoveBG?: boolean;
  isAIDescription?: boolean;
};

export const MediaList: React.FC<MediaListProps> = observer(
  ({
    subTitle,
    actionBtnText,
    limit,
    onActionClick,
    type,
    productId,
    // NOTE: withoutSku undefined is intentionally
    withoutSku,
    isRemoveBG = false,
    isAIDescription = false,
  }) => {
    const mediaContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const { mediaStore, tooltipStore, localeStore, productsStore } = useStores();

    const { canRemoveBackground, removeBackgroundLimit, canRemoveBGAmount } = useLimits();

    const itemsLength = mediaStore.mediaList.length;
    const selectedAmount = mediaStore.selectedMediaAmount;
    const isLoading = mediaStore.uploadingMedia || mediaStore.loading || productsStore.loading;

    const blinksSelected = useMemo(() => {
      return mediaStore.selectedMediaList.filter((media) => media.type === MediaType.blink).length;
    }, [mediaStore.selectedMediaList]);
    const hasARLimit = isAIDescription && blinksSelected > 1;

    const isRBFreeLimit = !canRemoveBackground && canRemoveBGAmount <= 0;

    const hasRBLimit = isRemoveBG && isRBFreeLimit;

    const isNextDisabled = !selectedAmount || hasARLimit || hasRBLimit;

    useDidMount(() => {
      if (productId) return;
      mediaStore.loadMediaList({ type, withoutSku, page: 1, limit: 20 });
    });

    useDidUnmount(() => mediaStore.resetStore());

    const handleBrowse = () => inputRef.current?.click();

    const handleUploadMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
      tooltipStore.open(
        <Typography size="extraSmall">{localeStore.t('create["media-list"].upload')}</Typography>,
        {
          target: e.currentTarget,
          appearance: "secondary",
          position: "bottom",
          withAngle: true,
        },
      );
    };

    const handleUploadMouseLeave = () => tooltipStore.close();

    const handleDropAccepted = (acceptedFiles: File[]) => {
      const container = mediaContainerRef.current;

      if (container) {
        container.scrollTop = 0;
      }

      mediaStore.uploadMedia(
        filteredMap(acceptedFiles, (file) => {
          if ([".jpeg", ".jpg", ".png"].some((format) => file.name.includes(format))) {
            return { files: file, types: "image" };
          }

          if ([".quicktime", ".mp4"].some((format) => file.name.includes(format))) {
            return { files: file, types: "video" };
          }

          return undefined;
        }),
      );
    };

    const handleUnselectMedia = () => mediaStore.unselectAllMediaList("selected");

    const handleActionClick = () => onActionClick(mediaStore.selectedMediaList);

    const handleActionMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
      if (hasARLimit) {
        tooltipStore.open(
          <Typography size="extraSmall">
            {localeStore.t('create["media-list"]["ar-limit-text"]')}
          </Typography>,
          {
            target: e.currentTarget,
            appearance: "secondary",
            position: "bottomRight",
            withAngle: true,
          },
        );
      }

      if (hasRBLimit) {
        limitFeatureTooltip(removeBackgroundLimit, e.currentTarget, {
          position: "bottomRight",
        });
      }
    };

    const handleActionMouseLeave = () => tooltipStore.close();

    return (
      <div className={styles.mediaListContainer}>
        <div className={styles.mediaListNavbar}>
          <div className={styles.mediaGroupWrapper}>
            {selectedAmount ? (
              <Fragment>
                <div>
                  <Typography size="small600" color="textSecondary">
                    {selectedAmount} {localeStore.t('create["media-list"].image')}
                  </Typography>
                  &nbsp;
                  <Typography size="small" color="textSecondary">
                    {localeStore.t('create["media-list"].selected')}
                  </Typography>
                </div>

                <Button
                  className={styles.selectBtn}
                  appearance="secondary"
                  size="small"
                  onClick={handleUnselectMedia}
                >
                  {localeStore.t('create["media-list"].buttons.unselect')}
                </Button>
              </Fragment>
            ) : (
              <Typography color="textTertiary" size="medium">
                {subTitle}
              </Typography>
            )}
          </div>
          <div className={styles.mediaGroupWrapper}>
            <div onMouseEnter={handleActionMouseEnter} onMouseLeave={handleActionMouseLeave}>
              <Button
                className={styles.actionBtn}
                onClick={handleActionClick}
                disabled={isNextDisabled}
                data-cy="next-btn"
              >
                {actionBtnText}
              </Button>
            </div>
            <Dropzone
              accept={createPageUploaderAccept}
              maxSize={createPageMaxSizeInBytes}
              maxFiles={createPageMaxFiles}
              onDropAccepted={handleDropAccepted}
              preventDropOnDocument
              multiple
            >
              {({ getRootProps, getInputProps }) => (
                <Button
                  {...getRootProps(
                    {
                      onClick: handleBrowse,
                      onMouseEnter: handleUploadMouseEnter,
                      onMouseLeave: handleUploadMouseLeave,
                    },
                    true,
                  )}
                  appearance="tertiaryOutlined"
                  data-cy="upload-btn"
                >
                  <input {...getInputProps({ forwardRef: inputRef })} />
                  <SvgIcon icon={UploadCloudSVG} />
                </Button>
              )}
            </Dropzone>
          </div>
        </div>
        <div className={styles.mediasContainer}>
          {isLoading && <Loader type="goo-loader" position="absolute" withOverlay />}

          {!itemsLength && !isLoading && (
            <FileUploadFullscreen
              className={styles.uploadContainer}
              title={localeStore.t('create["file-upload-full-screen"].title')}
              subtitle={localeStore.t('create["file-upload-full-screen"].subtitle')}
              orText={localeStore.t('create["file-upload-full-screen"]["or-text"]')}
              uploadButtonText={localeStore.t(
                'create["file-upload-full-screen"]["upload-button-text"]',
              )}
              backdropText={localeStore.t('create["file-upload-full-screen"]["backdrop-text"]')}
              accept={createPageUploaderAccept}
              maxSize={createPageMaxSizeInBytes}
              maxFiles={createPageMaxFiles}
              onDropAccepted={handleDropAccepted}
              preventDropOnDocument
              multiple
            />
          )}

          <MediaItems limit={limit} forwardRef={mediaContainerRef} />
        </div>
      </div>
    );
  },
);

export default MediaList;
