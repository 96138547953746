import React, { useMemo, useState } from "react";
import { FormikHelpers, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  clsx,
  globalStyles,
  Nullable,
  Form,
  ObjectType,
  useFetch,
  Loader,
  Heading,
  TextEditorUtils,
} from "@gemlightbox/core-kit";

import { getProduct } from "src/api";
import {
  ProductAttributes,
  ProductHeader,
  ProductMainMedia,
  ProductMediasBar,
} from "src/external-ts/components";
import { useStores } from "src/hooks";
import { AttributeType, ProductImageModel, ProductModel, ProductRequestModel } from "src/models";
import { CREATE_PAGE, DEFAULT_PAGE, ErrorCodes } from "src/constants";
import { getAttributesForRequest } from "src/utils";
import { MAX_MEDIA_IN_PRODUCT } from "src/containers/products";
import { formId } from "./product-edit.page.constants";

import styles from "./product-edit.page.module.css";

export const ProductEditPage: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();

  const navigate = useNavigate();

  const useProductFetch = useFetch(getProduct.setParams({ productId: productId as any }));
  useProductFetch.onFetchSuccess((data) => {
    setSelectedMedia(data.images[0]);
  });
  useProductFetch.onFetchError(() => navigate(DEFAULT_PAGE.path));

  const product = useProductFetch.payload;
  const productMedias = product?.images || [];

  const [selectedMedia, setSelectedMedia] = useState<Nullable<ProductImageModel>>(null);
  const [formInner, setFormInner] = useState<FormikProps<any> | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    localeStore,
    modalsStore,
    notificationStore,
    attributesStore,
    productsStore,
    mediaStore,
  } = useStores();

  const initialValues = useMemo(() => {
    if (!product) return null;
    const reduced = attributesStore.initialAttributes.reduce<ObjectType>((acc, attribute) => {
      if (attribute.kind === "default") {
        if (attribute.name === "description") {
          acc[attribute.name] = TextEditorUtils.normalizeEditorValue(product.description || "");
        } else {
          acc[attribute.name] = product[attribute.name as keyof ProductModel];
        }
      } else {
        const parameter = product.parameters?.find(({ name }) => name === attribute.name);
        if (parameter) {
          const { value } = parameter;
          if (attribute.type === AttributeType.multiselect) {
            acc[attribute.name] = value?.split(", ") || [];
          } else {
            acc[attribute.name] = value || "";
          }
        }
      }
      return acc;
    }, {});

    if (reduced.description === undefined) {
      reduced.description = TextEditorUtils.normalizeEditorValue("");
    }

    return reduced;
  }, [attributesStore.columnsAttributes.length, product]);

  const handleGoBack = () => {
    productsStore.setScrollRestoration(true);
    navigate(-1);
  };

  const handleConfirmGoBack = () => {
    if (formInner?.dirty) {
      notificationStore.open({
        title: localeStore.t('common.warnings["confirm-action"]'),
        message: localeStore.t('common.warnings["exit-without-saving"]'),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: localeStore.t("common.buttons.cancel"),
        icon: "exclamation",
        onOk: handleGoBack,
      });
    } else {
      handleGoBack();
    }
  };

  const handleSelectMedia = (media: Nullable<ProductImageModel>) => setSelectedMedia(media);

  const handleSetProductMedias = (medias: ProductImageModel[]) => {
    if (!product) return;

    useProductFetch.actions.setData({ ...product, images: medias });

    if (!medias.length) return handleSelectMedia(null);

    if (selectedMedia) {
      const hasMedia = medias.find((media) => media.id === selectedMedia.id);
      if (!hasMedia) return handleSelectMedia(medias[0]);
    }

    if (!selectedMedia && medias.length) handleSelectMedia(medias[0]);
  };

  const handleBlinkClick = () => {
    if (!product) return;
    navigate(CREATE_PAGE.path + "/augmented-reality-product/" + product._id);
  };

  const handleRemoveMedia = async (media: ProductImageModel) => {
    if (!product) return;

    const res = await mediaStore.unassignMedia(media.id);
    if (res.status !== "success") return;

    const newMedias = productMedias.filter((prevMedia) => prevMedia.id !== media.id);
    handleSetProductMedias(newMedias);
  };

  const handleAddMediaClick = () => {
    if (!product) return;

    modalsStore.open("ProductAssignMediasSidebar", {
      productImages: productMedias,
      onSubmit: async (medias) => {
        const mediasIds = medias.map(({ id }) => id);
        const res = await mediaStore.assignMedia(mediasIds, product);
        if (res.status !== "success") return;

        await useProductFetch.refresh();

        // NOTE: it should be this code, but not refresh above, to not have useless api call
        // but then there is issue with images ordering, fix it later (TODO)
        // const newMedias = productMedias.concat(medias as any);
        // handleSetProductMedias(newMedias);
      },
    });
  };

  const handleReplaceMedia = (
    mediaId: ProductImageModel["id"],
    newMedia: ProductImageModel,
    asNew: boolean,
  ) => {
    if (asNew && productMedias.length >= MAX_MEDIA_IN_PRODUCT) return;
    useProductFetch.refresh();
  };

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    if (!product) return;

    const data: ProductRequestModel = {
      // title for backwards compatability with attributesVersion v1
      title: values.title,
      parameters: getAttributesForRequest(values, attributesStore.attributes, product.parameters),
    };

    setIsSubmitting(true);

    const result = await productsStore.updateProduct(product._id, data);

    setIsSubmitting(false);

    if (result?.error) {
      if (
        result.details.statusCode === 400 &&
        result.error.originalError?.code === ErrorCodes.PRODUCT_TITLE_EXISTS
      ) {
        // TODO: localize it?
        helpers.setFieldError("title", "Product with provided SKU already exists.");
      }

      return;
    }

    handleGoBack();
  };

  if (useProductFetch.loading) {
    return (
      <div className={styles.productEditPageContainer}>
        <ProductHeader
          title={localeStore.t('["product-edit"].title')}
          formId={formId}
          onGoBack={handleConfirmGoBack}
          onCancel={handleConfirmGoBack}
          mainDisabled
        />
        <div className={clsx(styles.productEditPageContent, globalStyles.addScrollStyles)}>
          <div className={styles.pageLoading}>
            {useProductFetch.loading && <Loader type="goo-loader" />}
            <Heading tag="h3" color="textSecondary">
              {localeStore.t('common.warnings["loading-dots"]')}
            </Heading>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.productEditPageContainer}>
      <ProductHeader
        title={localeStore.t('["product-edit"].title')}
        formId={formId}
        onGoBack={handleConfirmGoBack}
        onCancel={handleConfirmGoBack}
        disableSave={!formInner?.dirty}
        loading={isSubmitting}
      />

      <div className={clsx(styles.productEditPageContent, globalStyles.addScrollStyles)}>
        <div className={styles.leftWrapper}>
          <ProductMainMedia
            productImage={selectedMedia}
            onAddMediaClick={handleAddMediaClick}
            onMediaReplace={handleReplaceMedia}
            canAddMedia={!productMedias.length}
          />
          <ProductMediasBar
            productMedias={productMedias}
            onAddMediaClick={handleAddMediaClick}
            onRemoveMediaClick={handleRemoveMedia}
            onMediaClick={handleSelectMedia}
            onMediaDrag={handleSetProductMedias}
            productId={product?._id}
            onBlinkClick={handleBlinkClick}
          />
        </div>

        <Form
          className={clsx(styles.productFormContainer, globalStyles.addScrollStyles)}
          contentClassName={styles.productFormContent}
          formId={formId}
          initialValues={initialValues}
          innerRef={setFormInner}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <ProductAttributes productMedias={productMedias} />
          {/* remove this requirement. see https://www.notion.so/gemlightbox/Shopify-18d38e4da16047bd82152d79adeab6ad#4ca7dc24cf3c4d16aeac8c2732671b05 */}
          {/* <ProductVariant /> */}
        </Form>
      </div>
    </div>
  );
});

export default ProductEditPage;
