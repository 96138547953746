import React from "react";
import { observer } from "mobx-react-lite";
import { Heading, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { GlobalImageDataModel } from "src/models";
import { Link } from "../link";
import { Logo, LogoImageType } from "../../logo";

import styles from "./link-settings.module.css";

interface LinkSettingsProps {
  initialLogo: GlobalImageDataModel | string | null;
  logo: GlobalImageDataModel | string | null;
  resetLogo: boolean;
  handleLogoChange: (data: LogoImageType) => void;
}

export const LinkSettings: React.FC<LinkSettingsProps> = observer(
  ({ logo, initialLogo, resetLogo, handleLogoChange }) => {
    const { localeStore } = useStores();

    const logoToSet = resetLogo ? initialLogo : logo;

    return (
      <div className={styles.container} data-cy="link-settings">
        <Heading className={styles.title} tag="h2" color="textSecondary">
          {localeStore.t('settings["share-settings"]["link-settings"].title')}
        </Heading>
        <div className={styles.companyLogoContainer}>
          <div className={styles.companyLogoText}>
            <Typography
              className={styles.companyLogoTitle}
              size="small600"
              color="textSecondary"
              data-cy="link-settings-logo-title"
            >
              {localeStore.t('settings["share-settings"]["link-settings"].subtitle')}
            </Typography>
            <Typography
              className={styles.companyLogoDescription}
              size="extraSmall"
              color="textTertiary"
              data-cy="link-settings-logo-description"
            >
              {localeStore.t('settings["share-settings"]["link-settings"].description')}
              <Link url="https://support.picupmedia.com/creating-and-customizing-share-link" />
            </Typography>
          </div>
          <div>
            <Logo appearance="primaryV2" initialLogo={logoToSet} onImageChange={handleLogoChange} />
          </div>
        </div>
      </div>
    );
  },
);

export default LinkSettings;
