import React, { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  useDidMount,
  useDidUnmount,
  Pagination,
  FileUploadFullscreen,
  filteredMap,
  uniqId,
  useDidUpdate,
  Heading,
  Typography,
  Modal,
} from "@gemlightbox/core-kit";

import {
  createPageMaxFiles,
  createPageMaxSizeInBytes,
  createPageUploaderAccept,
} from "src/external-ts/pages/create/create.constants";
import ProgressLine from "src/components/ProgressLine";
import WSConnection from "src/common/socket";
import { PageHeader, PageContainer } from "src/external-ts/components";
import { usePageViewEvent, useStores } from "src/hooks";
import { MediaListPlaceholder } from "./media-list-placeholder";
import { MediaList } from "./media-list";
import { MediaSelectedFiltersPanel } from "./media-selected-filters-panel";
import { MediaSelectionPanel } from "./media-selection-panel";
import { MediaControlPanel } from "./media-control-panel";
import { WSMediaReloadPayload } from "./media.types";

import styles from "./media.module.css";
import CloudStorageFullAlert from "src/components/CloudStorageFullAlert";

export const Media: React.FC = observer(() => {
  usePageViewEvent();

  const { mediaStore, localeStore, userStore, modalsStore } = useStores();

  const [mediaConnection] = useState(() => new WSConnection(`/media`, userStore.token));

  const [sentFilesIds, setSentFilesIds] = useState<string[]>([]);
  const [processedFiles, setProcessedFiles] = useState(0);
  const mediaListContainerRef = useRef<HTMLDivElement>(null);

  const uploadProgress = mediaStore.uploadingProgress?.progress || 0;
  const processingProgress = (processedFiles / sentFilesIds.length) * 100;
  const isInUpload = uploadProgress !== 100;
  const progressToShow = isInUpload ? uploadProgress : processingProgress;

  useDidMount(() => {
    mediaStore.loadMediaList();
    mediaStore.loadMediaPendingUploadList();
  });

  useDidMount(async () => {
    mediaConnection.on("media/RELOAD", ({ payload }: WSMediaReloadPayload) => {
      /* IMPORTANT
       *  do not update media list if medias were changed (payload.touchedIDS)
       *  because it will change reference to media objects and cause bugs
       *  in MediaDetailsSidebar and other places where media is changed
       *  */

      // if (!payload.createdIDS || !payload.createdIDS.length) return;
      mediaStore.loadMediaList();
      mediaStore.loadMediaPendingUploadList();
    });
  });

  useDidUpdate(() => {
    mediaConnection.on("media/UPLOAD_STATUS", ({ payload }: any) => {
      const fileId = payload?.media_data?.uploadID;
      const validFile = !!sentFilesIds.find((id) => id === fileId);
      if (validFile) setProcessedFiles((prevState) => prevState + 1);
    });
  }, [sentFilesIds]);

  useDidUnmount(() => {
    mediaConnection.destroy();
  });

  const handlePageChange = async (page: number) => {
    await mediaStore.loadMediaList({ ...mediaStore.filters, page });
    mediaListContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleLimitChange = (limit: number) => {
    mediaStore.loadMediaList({ ...mediaStore.filters, limit, page: 1 });
    mediaListContainerRef.current?.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleDropAccepted = async (acceptedFiles: File[]) => {
    const data = filteredMap(acceptedFiles, (file) => {
      if ([".jpeg", ".jpg", ".png"].some((format) => file.name.includes(format))) {
        return { uploadIDs: uniqId(), files: file, types: "image" };
      }

      if ([".quicktime", ".mp4"].some((format) => file.name.includes(format))) {
        return { uploadIDs: uniqId(), files: file, types: "video" };
      }

      return undefined;
    });

    const ids = data.map(({ uploadIDs }) => uploadIDs);
    setSentFilesIds(ids);

    mediaStore.uploadMedia(data as any);
  };

  return (
    <PageContainer>
      <PageHeader title={localeStore.t("media.title")} showSubscriptionBadge />
      <CloudStorageFullAlert />
      {!!modalsStore.modals.UploadMediaModal || (
        <FileUploadFullscreen
          className={styles.uploadContainer}
          backdropText={localeStore.t('create["file-upload-full-screen"]["backdrop-text"]')}
          accept={createPageUploaderAccept}
          maxSize={createPageMaxSizeInBytes}
          maxFiles={createPageMaxFiles}
          onDropAccepted={handleDropAccepted}
          preventDropOnDocument
          multiple
        />
      )}
      <MediaControlPanel />
      <MediaSelectedFiltersPanel />
      <MediaSelectionPanel />

      {mediaStore.mediaAmount === 0 && <MediaListPlaceholder />}
      {mediaStore.mediaAmount !== 0 && (
        <>
          <MediaList containerRef={mediaListContainerRef} />
          <Pagination
            totalItemsText={localeStore.t('components.ui.pagination["total-items"]')}
            prevText={localeStore.t("components.ui.pagination.prev")}
            nextText={localeStore.t("components.ui.pagination.next")}
            perPageText={localeStore.t('components.ui.pagination["per-page"]')}
            page={mediaStore.filters.page || 0}
            totalPages={mediaStore.totalPages}
            totalItems={mediaStore.resultsMediaAmount}
            limit={mediaStore.filters.limit || 50}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
            data-cy="pagiantion"
            data-name={`total-items-${mediaStore.resultsMediaAmount}`}
          />
        </>
      )}
      <Modal
        scrollWrapperClassName={styles.modalScrollContent}
        contentClassName={styles.modalContent}
        isOpen={mediaStore.uploadingMedia}
        onFinalClosed={() => setProcessedFiles(0)}
      >
        <Heading className={styles.modalHeading} color="textSecondary" tag="h3">
          {localeStore.t("create.modal.title")}
        </Heading>

        <div className={styles.modalProcessWrapper}>
          <Typography size="small600" color="textSecondary">
            {isInUpload
              ? localeStore.t("create.modal.processing.upload")
              : localeStore.t("create.modal.processing.process")}
          </Typography>
          <Typography size="extraSmall500" color="textPrimary">
            {isInUpload ? `${uploadProgress}%` : `${processedFiles}/${sentFilesIds.length}`}
          </Typography>
        </div>

        <ProgressLine progress={progressToShow} />
      </Modal>
    </PageContainer>
  );
});
