import { AttributeModel, ProductModel, ProductParameterRequestModel } from "src/models";
import { MediaType } from "src/models";

export const getProductARBlinkMediaList = (productMedia: any) => {
  return productMedia === 0
    ? []
    : productMedia.filter(({ type }: { type: string }) => type === MediaType.blink);
};

export const getParametersForProductUpdate = (
  product: ProductModel,
  attributes: AttributeModel[],
  newAttributeName: string,
  newAttributeValue: string | null,
) => {
  const { title, parameters } = product;
  const newParameters = [] as ProductParameterRequestModel[];

  if (parameters.some((parameter) => parameter.name === newAttributeName)) {
    const parsedParameters = parameters.map((parameter) => {
      let value = parameter.value;

      if (parameter.name === newAttributeName) {
        value = newAttributeValue;
      }

      if (parameter.name === "title") {
        value = title;
      }

      return { attribute_id: parameter.id, value };
    });

    newParameters.push(...parsedParameters);
  } else {
    const parsedParameters = parameters.map((parameter) => {
      let value = parameter.value;

      if (parameter.name === "title") {
        value = title;
      }

      return {
        attribute_id: parameter.id,
        value,
      };
    });

    const newAttribute = attributes.find((attribute) => attribute.name === newAttributeName);
    const newParameter = {
      attribute_id: newAttribute?.id || 0,
      value: newAttributeValue,
    };

    newParameters.push(...parsedParameters, newParameter);
  }

  return newParameters;
};

export const generateCatalog = (products: ProductModel[]) => {
  const productsIds = products.map((product) => product._id);

  return {
    product_ids: productsIds,
    isGenerated: true,
  };
};
