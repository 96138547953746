import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Typography, SvgIcon, Tooltip, Image, useBoolean } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { ColorPickerContent } from "../../../color-picker-content";
import { GroupBlock } from "../../group-block";

import transparentImg from "src/external-ts/assets/images/edit-media/transparent-background-smaller.jpg";
import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import styles from "./color.module.css";

export const Color: React.FC = observer(() => {
  const isAddColorOpenBoolean = useBoolean();

  const addColorButtonRef = useRef<HTMLButtonElement>(null);

  const { localeStore } = useStores();
  const { bgColors, currentBGColor, tempBGColor } = editMediaStore;

  const handleToggleAddColorTooltip = () => {
    isAddColorOpenBoolean.trigger();
    editMediaStore.setTempColor(currentBGColor);
  };

  const handleAddColorClick = () => {
    isAddColorOpenBoolean.setFalsy();
    editMediaStore.addBGColor(tempBGColor);
  };

  const handleTempColorChange = (color: string) => editMediaStore.setTempColor(color);

  const handleCloseTooltip = () => {
    isAddColorOpenBoolean.setFalsy();
    editMediaStore.setTempColor(currentBGColor);
  };

  const handleSelectColor = (color: string) => () => editMediaStore.changeBGColor(color);

  return (
    <GroupBlock className={styles.colorBlockGroup}>
      <Typography size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.background["background-color"]')}
      </Typography>
      <div className={styles.colorsWrapper}>
        <Button
          appearance="primaryOutlined"
          size="small"
          forwardRef={addColorButtonRef}
          onClick={handleToggleAddColorTooltip}
        >
          <SvgIcon icon={PlusSVG} />
        </Button>
        <Tooltip
          className={styles.colorPickerTooltip}
          appearance="unset"
          position="right"
          offsetX={4}
          target={addColorButtonRef}
          onClose={handleCloseTooltip}
          isOpen={isAddColorOpenBoolean.value}
          disableOnOutsideClick
          disableAutoPosChange
        >
          <ColorPickerContent
            color={tempBGColor}
            onColorChange={handleTempColorChange}
            onClose={handleCloseTooltip}
            onAddColorClick={handleAddColorClick}
          />
        </Tooltip>

        {bgColors.map((color) => (
          <Button key={color} appearance="unset" size="unset" onClick={handleSelectColor(color)}>
            <div className={styles.color} style={{ backgroundColor: color }}>
              {color === "#00000000" && <Image src={transparentImg} />}
              {currentBGColor === color && (
                <SvgIcon className={styles.checkmark} icon={CheckmarkSVG} size="small" />
              )}
            </div>
          </Button>
        ))}
      </div>
    </GroupBlock>
  );
});

export default Color;
