import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Modal, Heading, useCall, useDidMount, useDidUnmount, clsx } from "@gemlightbox/core-kit";

import { getUserTagManagerInfoCallback, postProduct } from "src/api";
import { useStores } from "src/hooks";
import { pushDataLayerEvent } from "src/utils";
import { getInvalidRowsCount } from "src/containers/products-import";
import { GlobalErrorsMapModel, ProductRequestModel } from "src/models";
import { ErrorCodes, PRODUCTS_PAGE } from "src/constants";
import DangerBadge from "src/components/badges/DangerBadge";
import { InvalidView } from "./invalid-view";
import { ValidView } from "./valid-view";
import { LoadingView } from "./loading-view";
import { BatchStatusType, Params, RequestParamsKeys } from "./finish-import-modal.types";
import { getRequestParams } from "./finish-import-modal.utils";

import styles from "./finish-import-modal.module.css";

export interface FinishImportModalProps {
  isOpen: boolean;
  options: {
    totalProducts: ProductRequestModel[];
    validProducts: ProductRequestModel[];
    errors: GlobalErrorsMapModel;
    connection: any;
  };
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
}

export const FinishImportModal: React.FC<FinishImportModalProps> = observer(
  ({ options, isOpen, setClose, onFinalClosed }) => {
    const { totalProducts, validProducts, errors, connection } = options;

    const navigate = useNavigate();

    const { localeStore } = useStores();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState("");
    const [params, setParams] = useState<Params>({
      overwrite_duplicates: false,
      ignore_duplicates: false,
      rename_duplicates: false,
    });

    const totalProductsCount = totalProducts.length;
    const invalidProductsCount = getInvalidRowsCount(errors);
    const isValid = invalidProductsCount === 0;

    const createProductsCall = useCall(
      postProduct.setQueryParams({ background: true, batch_size: 10, ...getRequestParams(params) }),
    );
    createProductsCall.onCallSuccess(() => {
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "import_products",
          total: validProducts.length,
          user_id: response.user_id,
          account_type: response.account_type,
          is_trial: response.isTrial,
        });
      });
    });
    createProductsCall.onCallError(({ originalError }) => {
      if (originalError?.code === ErrorCodes.PRODUCT_TITLE_EXISTS) {
        setError(
          localeStore.t(
            '["products-import"].modals["finish-import-modal"].errors["products-with-sku-already-exist"]',
          ),
        );
      } else {
        setError(localeStore.t('["products-import"].modals["finish-import-modal"].errors.default'));
      }

      setLoading(false);
    });

    useDidMount(() => {
      connection.on("product/BATCH_STATUS", ({ payload }: BatchStatusType) => {
        const { completed, total, message, status } = payload;

        if (status === "in_progress") {
          const p = Math.ceil(completed * 100) / total;

          if (p === 100) {
            setClose();
            navigate(PRODUCTS_PAGE.path);
          } else {
            setProgress(p);
          }

          return;
        }

        if (status === "completed") {
          setClose();
          navigate(PRODUCTS_PAGE.path);
          return;
        }

        if (status === "error") {
          setLoading(false);
          // TODO: ask backend developer to change message or send status code
          setError(
            message ===
              localeStore.t(
                '["products-import"].modals["finish-import-modal"].errors["product-already-exist"]',
              )
              ? localeStore.t(
                  '["products-import"].modals["finish-import-modal"].errors["sku-already-exist"]',
                )
              : message,
          );
        }
      });
    });

    useDidUnmount(() => {
      connection.off("product/BATCH_STATUS");
    });

    const handleChangeParam = (name: RequestParamsKeys) => (checked?: boolean) => {
      setParams((prev) => {
        const result: Params = { ...prev };

        for (const _key in result) {
          const param = _key as RequestParamsKeys;

          if (param === name) {
            if (checked !== undefined) {
              result[name] = checked; // crunch for overwrite_duplicates
            } else {
              result[name] = !result[name];
            }
          } else {
            result[param] = false;
          }
        }

        return result;
      });
    };

    const handleConfirm = () => {
      setError("");
      setLoading(true);
      createProductsCall.submit({
        data: validProducts,
      });
    };

    return (
      <Modal
        contentClassName={clsx(styles.modalContent, {
          [styles.valid]: isValid,
          [styles.loading]: loading,
        })}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        withCross={!loading}
      >
        {loading && <LoadingView progress={progress} />}
        {!loading && (
          <>
            <Heading tag="h2" color="textSecondary">
              {localeStore.t('["products-import"].modals["finish-import-modal"].title')}
            </Heading>

            {error && <DangerBadge> {error} </DangerBadge>}

            {isValid && (
              <ValidView
                overwriteDuplicates={params.overwrite_duplicates}
                ignoreDuplicates={params.ignore_duplicates}
                renameDuplicates={params.rename_duplicates}
                onOverwriteDuplicatesChange={handleChangeParam("overwrite_duplicates")}
                onSkipDuplicatesChange={handleChangeParam("ignore_duplicates")}
                onRenameDuplicates={handleChangeParam("rename_duplicates")}
                onCancel={setClose}
                onConfirm={handleConfirm}
              />
            )}
            {!isValid && (
              <InvalidView
                totalCount={totalProductsCount}
                invalidCount={invalidProductsCount}
                errors={errors}
              />
            )}
          </>
        )}
      </Modal>
    );
  },
);

export default FinishImportModal;
