import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";
import { Button, ButtonAppearanceKeys, Modal } from "@gemlightbox/core-kit";
import { ModalExtendedType } from "src/store/modals/modals.store.types";

import styles from "./custom-notification-modal.module.css";

export type CustomNotificationModalProps = ModalExtendedType<{
  title: Nullable<string> | React.ReactNode;
  message?: Nullable<string | React.ReactNode>;
  icon?: Nullable<ReactElement | React.ReactNode>;
  confirmAppearance?: ButtonAppearanceKeys;
  confirmText: string;
  onOk?: VoidFunction | (() => Promise<void>);
  onClose?: VoidFunction;
  withCross?: boolean;
}>;

export const CustomNotificationModal: React.FC<CustomNotificationModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const {
      confirmAppearance = "primary",
      title,
      message,
      confirmText,
      icon,
      withCross,
      onClose,
      onOk,
    } = options;
    const handleConfirm = () => {
      const callback = onOk?.();

      if (callback instanceof Promise) {
        callback.finally(() => {
          setClose();
          onClose?.();
        });
        return;
      }

      setClose();
      onClose?.();
    };

    const handleClose = () => {
      setClose();
      onClose?.();
    };

    return (
      <Modal
        contentClassName={styles.modalContent}
        name="notification-modal"
        data-cy="notification-modal"
        isOpen={isOpen}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
        disableBackdropClose
        withCross={withCross}
      >
        <div className={styles.content}>
          {icon && <div className={styles.iconWrapper}>{icon}</div>}
          <span className={styles.notificationTitle}>{title}</span>
          {message && <span className={styles.notificationDesc}>{message}</span>}
          <Button
            appearance={confirmAppearance}
            className={styles.notificationBtn}
            onClick={handleConfirm}
            data-cy="ok-button"
          >
            <span>{confirmText}</span>
          </Button>
        </div>
      </Modal>
    );
  },
);
