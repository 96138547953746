import { INTEGRATION_STATUSES } from "src/constants";
import { getAvailableParams, getSelectedParams } from "src/common/helpers/integrations.helpers";
import { LOGOUT } from "./user";

const ACTIVATE_LOADER = "shopify/ACTIVATE_LOADER";
const DEACTIVATE_LOADER = "shopify/DEACTIVATE_LOADER";

const LOAD_SHOPIFY_STATUS_SUCCESS = "shopify/LOAD_SHOPIFY_STATUS_SUCCESS";
export const LOAD_SHOPIFY_STATUS_FAILED = "shopify/LOAD_SHOPIFY_STATUS_FAILED";
const SILENT_CHANGE_SHOPIFY_STATUS = "shopify/SILENT_CHANGE_SHOPIFY_STATUS";
const START_SHOPIFY_SYNC = "shopify/START_SHOPIFY_SYNC";
const STOP_SHOPIFY_SYNC = "shopify/STOP_SHOPIFY_SYNC";
const LOAD_SHOPIFY_SETTINGS = "shopify/LOAD_SHOPIFY_SETTINGS";
export const LOAD_SHOPIFY_SETTINGS_FAILURE = "shopify/LOAD_SHOPIFY_SETTINGS_FAILURE";
const CONNECT_TO_SHOPIFY = "shopify/CONNECT_TO_SHOPIFY";
const REMOVE_SHOPIFY_INTEGRATION = "shopify/REMOVE_SHOPIFY_INTEGRATION";

const TOGGLE_SHOPIFY_SETUP_MODAL = "shopify/TOGGLE_SHOPIFY_SETUP_MODAL";
const TOGGLE_SHOPIFY_SELECT_STORE_MODAL = "shopify/TOGGLE_SHOPIFY_SELECT_STORE_MODAL";
const TOGGLE_SHOPIFY_CANCEL_SYNC_MODAL = "shopify/TOGGLE_SHOPIFY_CANCEL_SYNC_MODAL";
const TOGGLE_SHOPIFY_DISCONNECT_MODAL = "shopify/TOGGLE_SHOPIFY_DISCONNECT_MODAL";
const TOGGLE_SHOPIFY_ERRORS_MODAL = "shopify/TOGGLE_SHOPIFY_ERRORS_MODAL";

const SELECT_SHOPIFY_STORE = "shopify/SELECT_SHOPIFY_STORE";

const CHANGE_SELECTED_PARAMS = "shopify/CHANGE_SELECTED_PARAMS";

const LOAD_SHOPIFY_PRODUCTS = "shopify/LOAD_SHOPIFY_PRODUCTS";

const GET_SHOPIFY_FAILED_PRODUCTS_ERRORS = "shopify/GET_SHOPIFY_FAILED_PRODUCTS_ERRORS";

const initialState = {
  modals: {
    setup: {
      isOpened: false,
    },
    selectStore: {
      isOpened: false,
    },
    cancelSync: {
      isOpened: false,
    },
    disconnect: {
      isOpened: false,
    },
    errors: {
      isOpened: false,
      errors: null,
    },
  },
  loaded: false,
  totalProducts: 0,
  completedProducts: 0,
  errorProducts: 0,
  remainProducts: 0,
  syncStatus: "",
  lastSyncStarted: "",
  lastSyncCompleted: "",
  settingsList: [],
  settings: {
    availableParams: {
      standart: [],
      extended: [],
      custom: [],
    },
    selectedParams: {
      standart: [],
      extended: [],
      custom: [],
    },
  },
  maxCustomParams: 3,
  products: [],
  totalResults: 0,
};

export const shopifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case START_SHOPIFY_SYNC:
      return {
        ...state,
        isLoading: true,
        totalProducts: 0,
        completedProducts: 0,
        errorProducts: 0,
        remainProducts: 0,
        syncStatus: action.data.syncStatus || INTEGRATION_STATUSES.DEFAULT,
        lastSyncStarted: action.data.lastSyncStarted,
        lastSyncCompleted: action.data.lastSyncCompleted,
      };
    case DEACTIVATE_LOADER:
    case LOAD_SHOPIFY_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case LOAD_SHOPIFY_STATUS_SUCCESS:
    case SILENT_CHANGE_SHOPIFY_STATUS:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        totalProducts: action.data.total,
        completedProducts: action.data.completed,
        errorProducts: action.data.error,
        remainProducts: action.data.remain,
        syncStatus: action.data.syncStatus || INTEGRATION_STATUSES.DEFAULT,
        lastSyncStarted: action.data.lastSyncStarted,
        lastSyncCompleted: action.data.lastSyncCompleted,
      };
    case LOAD_SHOPIFY_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
        loaded: true,
      };
    case LOAD_SHOPIFY_SETTINGS:
      return {
        ...state,
        isLoading: false,
        settingsList: action.data.map((shopSettings) => {
          return {
            ...shopSettings,
            availableParams: getAvailableParams(shopSettings.availableParams),
            selectedParams: getSelectedParams(
              shopSettings.availableParams,
              shopSettings.selectedParams,
            ),
          };
        }),
      };
    case STOP_SHOPIFY_SYNC:
      return {
        ...state,
        isLoading: false,
        syncStatus: action.data.syncStatus,
      };
    case LOAD_SHOPIFY_PRODUCTS:
      return {
        ...state,
        products: action.data.products.rows,
        totalResults: action.data.products.total_count,
      };
    case TOGGLE_SHOPIFY_SETUP_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          setup: {
            ...state.modals.setup,
            isOpened: !state.modals.setup.isOpened,
          },
        },
      };
    case TOGGLE_SHOPIFY_SELECT_STORE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          selectStore: {
            ...state.modals.selectStore,
            isOpened: !state.modals.selectStore.isOpened,
          },
        },
      };
    case TOGGLE_SHOPIFY_CANCEL_SYNC_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          cancelSync: {
            ...state.modals.cancelSync,
            isOpened: !state.modals.cancelSync.isOpened,
          },
        },
      };
    case TOGGLE_SHOPIFY_DISCONNECT_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          disconnect: {
            ...state.modals.disconnect,
            isOpened: !state.modals.disconnect.isOpened,
          },
        },
      };
    case CHANGE_SELECTED_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedParams: action.params,
        },
      };
    case REMOVE_SHOPIFY_INTEGRATION:
      return {
        ...state,
        syncStatus: INTEGRATION_STATUSES.SETUP,
        settings: initialState.settings,
        settingsList: state.settingsList.filter(({ shop }) => shop !== action.shopName),
      };
    case TOGGLE_SHOPIFY_ERRORS_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          errors: {
            ...state.modals.errors,
            isOpened: !state.modals.errors.isOpened,
          },
        },
      };
    case GET_SHOPIFY_FAILED_PRODUCTS_ERRORS:
      return {
        ...state,
        modals: {
          ...state.modals,
          errors: {
            ...state.modals.errors,
            errors: state.products.reduce((acc, { title, syncMessage }) => {
              acc[title] = [syncMessage.detailedMessage || syncMessage.shortMessage];
              return acc;
            }, {}),
          },
        },
      };
    case SELECT_SHOPIFY_STORE:
      return {
        ...state,
        settings: state.settingsList.find(({ shop }) => shop === action.shop),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const loadShopifyStatus = (shop_url) => ({
  types: [ACTIVATE_LOADER, LOAD_SHOPIFY_STATUS_SUCCESS, LOAD_SHOPIFY_STATUS_FAILED],
  request: (client) => client.get("/api/shopify/status", { params: { shop_url } }),
  isAuthReq: true,
});

export const silentChangeShopifyStatus = (data) => ({
  type: SILENT_CHANGE_SHOPIFY_STATUS,
  data,
});

export const startSync = (data) => ({
  types: [START_SHOPIFY_SYNC, DEACTIVATE_LOADER],
  request: (client) => client.put("/api/shopify/sync", data),
  isAuthReq: true,
});

export const stopSync = (shop_url) => ({
  type: STOP_SHOPIFY_SYNC,
  request: (client) => client.put("/api/shopify/stop", { shop_url }),
  isAuthReq: true,
});

export const loadShopifySettings = () => ({
  types: [ACTIVATE_LOADER, LOAD_SHOPIFY_SETTINGS, LOAD_SHOPIFY_SETTINGS_FAILURE],
  request: (client) => client.get("/api/shopify/settings"),
  isAuthReq: true,
});

export const connectToShopify = (shop_url) => ({
  type: CONNECT_TO_SHOPIFY,
  request: (client) => client.get("/api/shopify/install", { params: { shop_url } }),
  isAuthReq: true,
});

export const removeShopifyIntegration = (shopName, shopURL) => ({
  type: REMOVE_SHOPIFY_INTEGRATION,
  request: (client) => client.put("/api/shopify/remove", { shop_url: shopURL }),
  isAuthReq: true,
  shopName,
});

export const toggleShopifySetupModal = () => ({ type: TOGGLE_SHOPIFY_SETUP_MODAL });
export const toggleShopifySelectStoreModal = () => ({ type: TOGGLE_SHOPIFY_SELECT_STORE_MODAL });
export const toggleShopifyDisconnectModal = () => ({ type: TOGGLE_SHOPIFY_DISCONNECT_MODAL });
export const toggleShopifyCancelSyncModal = () => ({ type: TOGGLE_SHOPIFY_CANCEL_SYNC_MODAL });
export const toggleShopifyErrorsModal = () => ({ type: TOGGLE_SHOPIFY_ERRORS_MODAL });

export const changeSelectedParams = (params) => ({
  type: CHANGE_SELECTED_PARAMS,
  params,
});

export const loadShopifyProducts = (filters) => ({
  type: LOAD_SHOPIFY_PRODUCTS,
  request: (client) => client.get("/api/shopify/products", { params: filters }),
  isAuthReq: true,
});

export const getShopifyFailedProductsErrors = () => ({ type: GET_SHOPIFY_FAILED_PRODUCTS_ERRORS });

export const selectShopifyStore = (shop) => ({
  type: SELECT_SHOPIFY_STORE,
  shop,
});
