import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  EllipseActions,
  ActionOption,
  SvgIcon,
  Image,
  clsx,
  Typography,
} from "@gemlightbox/core-kit";

import { ExtendedCatalogModel } from "src/store";
import { CatalogTypeTag } from "src/external-ts/components";
import { useStores } from "src/hooks";
import { CatalogName } from "./catalog-name";

import { ReactComponent as NoMediaSvg } from "src/external-ts/assets/images/media/add-media-grey.svg";
import { ReactComponent as NoProductSvg } from "src/external-ts/assets/images/products/no-product-gray.svg";
import { ReactComponent as PdfSVG } from "src/external-ts/assets/images/media/pdf-type.svg";
import { ReactComponent as EyeSVG } from "src/external-ts/assets/images/eye-grey.svg";
import { ReactComponent as ShareSVG } from "src/external-ts/assets/images/share-grey.svg";
import { ReactComponent as DeleteSVG } from "src/external-ts/assets/images/trash-grey.svg";
import { ReactComponent as DownloadCloudSVG } from "src/external-ts/assets/images/download-cloud-grey.svg";
import { ReactComponent as UserSVG } from "src/external-ts/assets/images/user.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import styles from "./catalog-grid-card.module.css";

import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import { PendingUploadStatus } from "src/models";

export type CatalogGridCardProps = {
  data: ExtendedCatalogModel;
  style?: React.CSSProperties;
};

export const CatalogGridCard: React.FC<CatalogGridCardProps> = observer(({ data, style }) => {
  const { catalogsStore, localeStore, userStore } = useStores();

  const navigate = useNavigate();

  const isSpecialCatalog = data.is_all || data.isRapnetCatalog;

  const handleRedirect = () => navigate(`/catalog/${data.id}`);

  const sortedProducts = data.products.slice().sort((first, second) => {
    if (first.images.length && second.images.length) return 0;
    if (second.images.length && !first.images.length) return 1;
    return -1;
  });

  const handleNameUpdate = (name: string) => {
    return catalogsStore.updateCatalog(data.id, { name });
  };

  const handleView = () => catalogsStore.actionView(data);

  const handleExportPDF = () => catalogsStore.actionExportPDF(data);

  const handleShare = () => catalogsStore.actionShare(data);

  const handleDelete = () => catalogsStore.actionDeleteCatalog(data);

  const catalogType = data.is_auto_catalog ? "automatic" : "manual";

  return (
    <div className={styles.container} style={style} data-cy="catalog-grid-card">
      <div className={styles.imagesContainer} onClick={handleRedirect}>
        <div className={styles.imageFiller} />
        <div className={clsx(styles.imageWrapper, { [styles.empty]: !sortedProducts.length })}>
          {!sortedProducts.length && <NoProductSvg />}

          {sortedProducts.map((product, index) => {
            if (index > 3) return null;

            const hasImage = !!product.images.length;
            const image = hasImage && product.images[0].file?.small;
            const isUploading =
              hasImage && product.images[0]?.status === PendingUploadStatus.pending;
            const isPdf = product?.images?.[0]?.type === "pdf";

            if (!image) {
              return (
                <div key={product._id} className={clsx(styles.productImageContainer, styles.empty)}>
                  {isUploading ? <SvgIcon icon={PendingUpSVG} size={32} /> : <NoMediaSvg />}
                </div>
              );
            }

            return (
              <div key={product._id} className={styles.productImageContainer}>
                {isPdf ? (
                  <SvgIcon icon={PdfSVG} />
                ) : (
                  <Image className={styles.productImage} src={image} alt={product.title} />
                )}
                {product.images.length > 1 && (
                  <div className={styles.productImagesCount}>{product.images.length}</div>
                )}
              </div>
            );
          })}
          {!data.is_all && (
            <div className={styles.tagContainer}>
              <CatalogTypeTag type={catalogType} />
            </div>
          )}
        </div>
      </div>

      <div className={styles.description}>
        <div>
          <CatalogName
            name={data.name}
            editable={!isSpecialCatalog}
            onNameUpdate={handleNameUpdate}
          />
          <div className={styles.creator}>
            <Typography
              color={
                userStore.userMe?.user._id === data.created._id ? "textPrimary" : "textTertiary"
              }
              lineHeight={1}
            >
              <UserSVG style={{ verticalAlign: "bottom" }} />
            </Typography>
            <Typography
              className={globalStyles.applySingleOverflow}
              size="extraSmall"
              color={
                userStore.userMe?.user._id === data.created._id ? "textPrimary" : "textTertiary"
              }
            >
              {data.created.first_name} {data.created.last_name}
            </Typography>
            <div className={styles.catalogProductNumber}>{`${data.total_products} ${localeStore.t(
              'catalog["catalogs-list"]["grid-view"]["catalog-grid-card"]["total-products-label"]',
            )}`}</div>
          </div>
        </div>

        <EllipseActions
          tooltipClassName={styles.actionsTooltipContainer}
          appearance="quaternary"
          size="tiny"
          position="topRight"
          data-cy="catalog-grid-card-actions-button"
        >
          <ActionOption onClick={handleView} data-cy="catalog-action-view">
            <SvgIcon icon={EyeSVG} />
            {localeStore.t("common.buttons.view")}
          </ActionOption>
          <ActionOption onClick={handleExportPDF} disabled={!data.total_products}>
            <SvgIcon icon={DownloadCloudSVG} />
            {localeStore.t('common.buttons["export-pdf"]')}
          </ActionOption>
          <ActionOption onClick={handleShare} data-cy="catalog-action-share">
            <SvgIcon icon={ShareSVG} />
            {localeStore.t("common.buttons.share")}
          </ActionOption>
          {!isSpecialCatalog && (
            <ActionOption onClick={handleDelete} data-cy="catalog-action-delete">
              <SvgIcon className={styles.deleteSVG} icon={DeleteSVG} />
              {localeStore.t("common.buttons.delete")}
            </ActionOption>
          )}
        </EllipseActions>
      </div>
    </div>
  );
});
