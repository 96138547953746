import React, { useRef, useState } from "react";
import { clsx, Heading, SvgIcon, Tooltip } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { getWhatsappShareLink, getFacebookShareLink } from "src/utils";

import { ReactComponent as MailSVG } from "src/external-ts/assets/images/mail-grey.svg";
import { ReactComponent as FacebookSVG } from "src/external-ts/assets/images/facebook.svg";
import { ReactComponent as WhatsappSVG } from "src/external-ts/assets/images/whatsapp-grey.svg";
import { ReactComponent as LinkedInSVG } from "src/external-ts/assets/images/linkedin-icon.svg";
import styles from "./share-options.module.css";

interface ShareOptionsProps {
  link: string;
}

export const ShareOptions: React.FC<ShareOptionsProps> = ({ link }) => {
  const { localeStore, linkedInStore } = useStores();

  const [tooltipOpen, setTooltip] = useState(false);
  const refLI = useRef(null);

  const handleWhatsapp = () => window.open(getWhatsappShareLink(link), "_blank");

  const handleFacebook = () => window.open(getFacebookShareLink(link), "_blank");

  const handleMail = () => window.open(`mailto:?body=${link}`, "_self");

  const handleLinkedIn = async () => {
    setTooltip(true);
    await linkedInStore.shareLink(link);
    setTimeout(() => setTooltip(false), 3000);
  };

  return (
    <div className={styles.container}>
      <Heading tag="h3" color="textSecondary">
        {localeStore.t('components.business["share-options"].title')}
      </Heading>

      <div className={styles.iconsContainer}>
        <div className={styles.mailIconContainer} onClick={handleMail}>
          <SvgIcon className={styles.mailIcon} icon={MailSVG} />
        </div>

        <SvgIcon
          className={clsx(styles.facebookIcon, styles.icon)}
          icon={FacebookSVG}
          size={48}
          onClick={handleFacebook}
        />

        {/*<SvgIcon*/}
        {/*  className={styles.icon}*/}
        {/*  icon={LinkedInSVG}*/}
        {/*  size={48}*/}
        {/*  onClick={handleLinkedIn}*/}
        {/*  forwardRef={refLI}*/}
        {/*/>*/}

        <SvgIcon
          className={clsx(styles.whatsappIcon, styles.icon)}
          icon={WhatsappSVG}
          size={48}
          onClick={handleWhatsapp}
        />
      </div>

      <Tooltip
        target={refLI}
        isOpen={tooltipOpen}
        appearance="secondary"
        onClose={setTooltip}
        withAngle
      >
        {linkedInStore.loading
          ? "..."
          : localeStore.t(
              'components.business["share-modal"]["generate-post-content"]["post-preview"]["linkedin-tooltip"]',
            )}
      </Tooltip>
    </div>
  );
};
