import { ReactComponent as FilterEclipseSVG } from "src/external-ts/assets/images/camera/filter-eclipse.svg";
import { ReactComponent as FilterBoxProSVG } from "src/external-ts/assets/images/camera/filter-gemLightbox-pro.svg";
import { ReactComponent as FilterBoxSVG } from "src/external-ts/assets/images/camera/filter-gemLightbox.svg";
import { ReactComponent as FilterMacroSVG } from "src/external-ts/assets/images/camera/filter-macro.svg";
import { ReactComponent as FilterManulSVG } from "src/external-ts/assets/images/camera/filter-manual.svg";
import { ReactComponent as FilterGemStackSVG } from "src/external-ts/assets/images/camera/filter-gemStack.svg";
import { ReactComponent as ScenarioDefaultSVG } from "src/external-ts/assets/images/camera/scenario-default.svg";
import { ReactComponent as ScenarioWhiteGoldSVG } from "src/external-ts/assets/images/camera/scenario-white-gold.svg";
import { ReactComponent as ScenarioLooseDiamondSVG } from "src/external-ts/assets/images/camera/scenario-loose-diamond.svg";
import { ReactComponent as ScenarioNaturalHueSVG } from "src/external-ts/assets/images/camera/scenario-natural-hue.svg";
import { GemAIMapType } from "../camera.types";

export const filterKindMap = [
  {
    label: "GemLightbox",
    src: FilterBoxSVG,
    value: 0,
    default: {
      u_brightness: 0.05,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: 0.22,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "GemLightbox Pro",
    src: FilterBoxProSVG,
    value: 1,
    default: {
      u_brightness: 0.05,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: 0.22,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "Eclipse",
    src: FilterEclipseSVG,
    value: 2,
    default: {
      u_brightness: 0,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: 0,
      u_exposureTime: 12,
      u_autoWhiteBalance: 0,
    },
  },
  {
    label: "Macro",
    src: FilterMacroSVG,
    value: 3,
    default: {
      u_brightness: 0.05,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: -0.4,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "GemStack",
    src: FilterGemStackSVG,
    value: 4,
    default: {
      u_brightness: 0.05,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: -0.4,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "Manual",
    src: FilterManulSVG,
    value: 5,
    default: {
      u_brightness: 0.05,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: -0.4,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
];

export const filterScenarioMap = [
  {
    label: "Default",
    src: ScenarioDefaultSVG,
    size: 28,
    value: 0,
    default: {
      u_brightness: 0.05,
      u_contrast: 0.25,
      u_saturation: 0,
      u_sharpness: 0.4,
      u_levelsTone: 0,
      u_levelsBrightness: 0.22,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "White Gold",
    src: ScenarioWhiteGoldSVG,
    size: 48,
    value: 1,
    default: {
      u_levelsBrightness: 0.28,
      u_contrast: 0,
      u_saturation: -0.2,
      u_sharpness: 0.4,
      u_levelsTone: -0.05,
      u_brightness: 0.05,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "Loose Diamond",
    src: ScenarioLooseDiamondSVG,
    size: 48,
    value: 2,
    default: {
      u_levelsBrightness: -0.2,
      u_contrast: 0.3,
      u_saturation: 0,
      u_sharpness: 0.5,
      u_levelsTone: 0,
      u_brightness: 0.05,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
  {
    label: "Natural Hue",
    src: ScenarioNaturalHueSVG,
    size: 48,
    value: 3,
    default: {
      u_levelsBrightness: 0.15,
      u_contrast: 0,
      u_saturation: 0,
      u_sharpness: 0,
      u_levelsTone: 0,
      u_brightness: 0.05,
      u_hue: 0,
      u_autoWhiteBalance: 1,
    },
  },
];

export const vedioSpeedMap = [
  {
    lang: "fast",
    label: "Fast",
    value: "0",
  },
  {
    lang: "slow",
    label: "Slow",
    value: "1",
  },
];

export const vedioAngleMap = [
  {
    label: "360°",
    value: 360,
  },
  {
    label: "90°",
    value: 90,
  },
  {
    label: "45°",
    value: 45,
  },
  {
    label: "15°",
    value: 15,
  },
];

export const gemAIMap: GemAIMapType = {
  description: {
    "0": {
      label: "GemLightbox",
      value: true,
      hidden: false,
      defalut: true,
    },
    "1": {
      label: "GemLightbox Pro",
      value: true,
      hidden: false,
      defalut: true,
    },
    "2": {
      label: "Eclipse",
      value: true,
      hidden: false,
      defalut: true,
    },
    "3": {
      label: "Macro",
      value: true,
      hidden: false,
      defalut: true,
    },
    "4": {
      label: "GemStack",
      value: true,
      hidden: false,
      defalut: true,
    },
    "5": {
      label: "Manual",
      value: true,
      hidden: false,
      defalut: true,
    },
  },
  background: {
    "0": {
      label: "GemLightbox",
      value: true,
      hidden: false,
      defalut: true,
    },
    "1": {
      label: "GemLightbox Pro",
      value: true,
      hidden: false,
      defalut: true,
    },
    "2": {
      label: "Eclipse",
      hidden: true,
      defalut: false,
    },
    "3": {
      label: "Macro",
      hidden: true,
      defalut: false,
    },
    "4": {
      label: "GemStack",
      value: false,
      hidden: false,
      defalut: false,
    },
    "5": {
      label: "Manual",
      value: true,
      hidden: false,
      defalut: true,
    },
  },
  retouch: {
    "0": {
      label: "GemLightbox",
      value: false,
      hidden: false,
      defalut: false,
    },
    "1": {
      label: "GemLightbox Pro",
      value: false,
      hidden: false,
      defalut: false,
    },
    "2": {
      label: "Eclipse",
      hidden: true,
      defalut: false,
    },
    "3": {
      label: "Macro",
      hidden: true,
      defalut: false,
    },
    "4": {
      label: "GemStack",
      value: true,
      hidden: false,
      defalut: true,
    },
    "5": {
      label: "Manual",
      value: false,
      hidden: false,
      defalut: false,
    },
  },
};
