import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  NotificationProviderMobx,
  FileQueueProvider,
  TooltipProviderMobx,
  ToastProviderMobx,
} from "@gemlightbox/core-kit";
import { NativeAPIResType } from "src/store/app-callback";

import { ModalsProviderMobx } from "src/external-ts/components";
import { store } from "src/redux/store";

import "@gemlightbox/core-kit/dist/assets/styles/index.css";
import "@gemlightbox/core-kit/dist/assets/styles/root.css";
import "src/common/scss/index.scss";
import { PlatformType } from "src/utils";

declare global {
  interface Window {
    dataLayer: any[];
    $appCallback: Record<string, (data: NativeAPIResType<any>) => void>;
    webkit: {
      messageHandlers: {
        PicupMediaNativeAPI: {
          postMessage: (payload: string) => void;
        };
      };
    };
    chrome: {
      webview: { postMessage: (payload: string) => void };
    };
    $platform: PlatformType;
  }
}

type ProvidersProps = {
  children: React.ReactNode;
};

export const Providers: React.FC<ProvidersProps> = ({ children }: ProvidersProps) => (
  <Provider store={store}>
    <BrowserRouter>
      <FileQueueProvider>
        <NotificationProviderMobx />
        <TooltipProviderMobx />
        <ToastProviderMobx />
        <ModalsProviderMobx />
        {children}
      </FileQueueProvider>
    </BrowserRouter>
  </Provider>
);

export default Providers;
