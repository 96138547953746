import { dashboardApiMiddleware } from "src/api";
import { UserModel, UserPayloadType, SubaccountModel } from "src/models";

export const getSubaccountsList = dashboardApiMiddleware<SubaccountModel[]>()({
  method: "get",
  endpoint: "/api/subaccount",
});

export const postCreateSubaccount = dashboardApiMiddleware<UserModel, UserPayloadType>()({
  method: "post",
  endpoint: "/api/subaccount",
});

export const deleteSubaccount = dashboardApiMiddleware<SubaccountModel[]>()({
  method: "delete",
  endpoint: "/api/subaccount/:userId",
});

export const postCreateInvitationLink = dashboardApiMiddleware<SubaccountModel[]>()({
  method: "post",
  endpoint: "/api/subaccount/invite/:email",
});

export const deleteDeactivateInvitationLink = dashboardApiMiddleware<SubaccountModel[]>()({
  method: "delete",
  endpoint: "/api/subaccount/invite/:email",
});

export const getVerifySubaccountGUID = dashboardApiMiddleware()({
  method: "get",
  endpoint: "/api/subaccount/:guid",
});
