import React, { useRef, useState } from "react";
import { useStores } from "src/hooks";
import { observer } from "mobx-react-lite";
import {
  Button,
  Dropzone,
  Heading,
  Range,
  Modal,
  SvgIcon,
  Webgl2New,
  bytesConverter,
  useDidMount,
  useDidUnmount,
  useDidUpdate,
  Select,
  Image,
  clsx,
  Vec2,
  PuffLoader,
  FileUpload,
} from "@gemlightbox/core-kit";
import { ModalExtendedType } from "src/store/modals";
import { ReactComponent as ChangeLogSVG } from "src/external-ts/assets/images/change-logo.svg";
import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-thick-grey.svg";
import { ReactComponent as HintSVG } from "src/external-ts/assets/images/hint-icon.svg";
import { ReactComponent as DelSVG } from "src/external-ts/assets/images/del-icon.svg";
import styles from "./logo-watermark-modal.module.css";
import { ExtendedLogoWatermarkModel, logoWatermarkModalStore } from "./logo-watermark-modal.store";

export type LogoWatermarkModalProps = ModalExtendedType<{
  onSuccess?: VoidFunction;
  originalSize?: Vec2;
}>;

export const LogoWatermarkModal: React.FC<LogoWatermarkModalProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { modalsStore, localeStore, notificationStore } = useStores();
    const { onSuccess, originalSize } = options;
    const { renderer, resolutionData, logoWatermarkList, logoWatermarkStatus } =
      logoWatermarkModalStore;

    const getResolutionData = () => {
      if (originalSize) {
        const r = logoWatermarkModalStore.getResolution(
          logoWatermarkModalStore.getAspectRatio(originalSize),
        );
        if (r) {
          return r;
        }
      }
      return resolutionData[0];
    };

    const [resolution, setRresolution] = useState(getResolutionData());
    const canvasSize = resolution.value;

    const selectedComponent = renderer?.selectedComponent;

    const handleSaveLogoWatermark = async () => {
      const promises = await logoWatermarkModalStore.saveLogoWatermark();
      const error = promises.find((res) => res?.error !== undefined)?.error;
      if (error) {
        notificationStore.open({
          title: localeStore.t('common.warnings["something-wrong"]'),
          message: error.formattedMessage,
          confirmText: localeStore.t("common.buttons.close"),
          cancelText: "",
          onlyConfirm: true,
        });
      } else {
        onSuccess?.();
        modalsStore.close("LogoWatermarkModal");
      }
    };

    const canvasWrapperRef = useRef<HTMLDivElement>(null);
    const inputUploadRef = useRef<HTMLInputElement>(null);
    const inputChangeLogRef = useRef<HTMLInputElement>(null);

    const handleDropAccepted = async (files: File[]) => {
      const originalFile = files[0];
      logoWatermarkModalStore.rendererLogoWatermarkFile(originalFile);
    };

    const handleCloseModal = () => {
      modalsStore.close("LogoWatermarkModal");
      setClose();
      Webgl2New.Webgl2.gl.enable(Webgl2New.Webgl2.gl.BLEND);
      Webgl2New.Webgl2.gl.blendFuncSeparate(
        Webgl2New.Webgl2.gl.SRC_ALPHA,
        Webgl2New.Webgl2.gl.ONE_MINUS_SRC_ALPHA,
        Webgl2New.Webgl2.gl.ONE,
        Webgl2New.Webgl2.gl.ONE_MINUS_SRC_ALPHA,
      );
    };

    const handleSelectResolution = (option: { value: string; label: string } | undefined) => {
      if (!option) return;
      const r = resolutionData.find((f) => f.name === option.value);
      r && setRresolution(r);
    };

    const handleSelectLogo = (id: number) => {
      logoWatermarkModalStore.selectLogoWatermark(id);
    };

    const handlerDeleteLogo = (
      e: React.SyntheticEvent,
      logoWatermark: ExtendedLogoWatermarkModel,
    ) => {
      logoWatermarkModalStore.deleteLogoWatermarkComponent(logoWatermark);
      e.stopPropagation();
    };

    const handleChangeLog = (files: File[]) => {
      const originalFile = files[0];
      logoWatermarkModalStore.changeLogoWatermark(originalFile);
    };

    useDidMount(async () => {
      if (canvasWrapperRef.current) {
        logoWatermarkModalStore.mount(canvasWrapperRef.current, canvasSize);
      }
    });

    useDidUpdate(() => {
      if (canvasWrapperRef.current) {
        logoWatermarkModalStore.logoWatermarkComponentChange();
        logoWatermarkModalStore.mount(canvasWrapperRef.current, canvasSize, false);
      }
    }, [resolution]);

    useDidUnmount(() => logoWatermarkModalStore.unmount());

    return (
      <Modal
        scrollWrapperClassName={styles.modalScrollContent}
        contentClassName={styles.modalContent}
        isOpen={isOpen}
        disableBackdropClose
        withCross
        setClose={handleCloseModal}
        onFinalClosed={onFinalClosed}
        name="logo-watermark-modal"
        data-cy="logo-watermark-modal"
      >
        <div className={styles.header}>
          <Heading tag="h2" color="textSecondary">
            {localeStore.t('camera["logo-watermark-modal"].title')}
          </Heading>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.canvasContainerWrapper}>
            {!logoWatermarkList ||
              (logoWatermarkList.length === 0 && (
                <FileUpload
                  className={styles.fileUpload}
                  title={localeStore.t('media.modals["upload-media-modal"]["file-upload"].title')}
                  subtitle={localeStore.t(
                    'media.modals["upload-media-modal"]["file-upload"]["subtitle-image"]',
                  )}
                  orText={localeStore.t(
                    'media.modals["upload-media-modal"]["file-upload"]["or-text"]',
                  )}
                  browseButtonText={localeStore.t(
                    'media.modals["upload-media-modal"]["file-upload"]["browse-button-text"]',
                  )}
                  onDropAccepted={handleDropAccepted}
                  accept="image/jpeg, image/jpg, image/png"
                  maxSize={bytesConverter(20, "Bytes", "MB")}
                />
              ))}
            <div ref={canvasWrapperRef} className={styles.canvasContainer}></div>
          </div>
          <div className={styles.operationContainerWrapper}>
            <div className={styles.resolutionSettings}>
              <span className={styles.resolutionTitle}>
                {localeStore.t('camera["logo-watermark-modal"].settings')}
              </span>
              <Select
                data-name="currency-dropdown"
                appearance="primaryV2"
                label={localeStore.t('camera["logo-watermark-modal"]["select-label"]')}
                selectedOptionsKeys={resolution.name}
                options={resolutionData.map((opt) => ({
                  value: opt.name,
                  label: opt.name,
                }))}
                onChange={handleSelectResolution}
                disableClearing
              />
            </div>
            <div className={styles.logoList}>
              <span className={styles.resolutionTitle}>
                {localeStore.t('camera["logo-watermark-modal"]["logo-files"]')}
              </span>
              <div className={styles.picturesWrapper}>
                {logoWatermarkList.map((item, index) => (
                  <Button
                    key={index}
                    className={clsx(styles.picture, {
                      [styles.active]: selectedComponent?.id === item.componentId,
                    })}
                    appearance="primaryOutlined"
                    size="unset"
                    onClick={() => handleSelectLogo(item.componentId)}
                  >
                    {logoWatermarkStatus === "changing" &&
                    selectedComponent?.id === item.componentId ? (
                      <PuffLoader size={24}></PuffLoader>
                    ) : (
                      <>
                        <Image src={item.logo} />
                        <div className={styles.delLogBtn}>
                          <SvgIcon
                            className={styles.deletemark}
                            icon={DelSVG}
                            size={20}
                            onClick={(e) => handlerDeleteLogo(e, item)}
                          />
                        </div>
                      </>
                    )}
                  </Button>
                ))}
                {logoWatermarkList.length < 2 && (
                  <Dropzone
                    accept="image/jpeg, image/jpg, image/png"
                    maxSize={bytesConverter(20, "Bytes", "MB")}
                    onDropAccepted={handleDropAccepted}
                    preventDropOnDocument
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Button
                        {...getRootProps(
                          {
                            onClick: () => inputUploadRef.current?.click(),
                          },
                          true,
                        )}
                        className={clsx(styles.picture, styles.active)}
                        appearance="primaryOutlined"
                        size="unset"
                      >
                        <input {...getInputProps({ forwardRef: inputUploadRef })} />

                        {logoWatermarkStatus === "uploading" ? (
                          <PuffLoader size={24}></PuffLoader>
                        ) : (
                          <SvgIcon icon={PlusSVG} size={40} />
                        )}
                      </Button>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>

            {selectedComponent instanceof Webgl2New.ImageAdjustRectComponent && (
              <>
                <Dropzone
                  accept="image/jpeg, image/jpg, image/png"
                  maxSize={bytesConverter(20, "Bytes", "MB")}
                  onDropAccepted={handleChangeLog}
                  preventDropOnDocument
                >
                  {({ getRootProps, getInputProps }) => (
                    <Button
                      appearance="secondaryOutlined"
                      className={styles.changeLogoBtn}
                      {...getRootProps(
                        {
                          onClick: () => inputChangeLogRef.current?.click(),
                        },
                        true,
                      )}
                    >
                      <input {...getInputProps({ forwardRef: inputChangeLogRef })} />
                      <SvgIcon icon={ChangeLogSVG} />
                      {localeStore.t('camera["logo-watermark-modal"]["change-logo"]')}
                    </Button>
                  )}
                </Dropzone>
                <span className={styles.resolutionTitle}>
                  {localeStore.t('camera["logo-watermark-modal"]["logo-opacity"]')}
                </span>
                <div className={styles.logoOpacityWrapper}>
                  <span>0%</span>
                  <Range
                    className={styles.logoOpacity}
                    min={0}
                    max={1}
                    step={0.01}
                    roundValue={2}
                    value={selectedComponent?.state?.filters?.u_opacity ?? 0}
                    onChange={(value) => {
                      if (selectedComponent) selectedComponent.state.filters.u_opacity = value;
                    }}
                    disableError
                  />
                  <span>
                    {((selectedComponent?.state?.filters?.u_opacity ?? 0) * 100).toFixed(0)}%
                  </span>
                </div>
              </>
            )}
            <div className={styles.buttonContainerWrapper}>
              <div className={styles.hint}>
                <SvgIcon icon={HintSVG} size={16} />
                <span>{localeStore.t('camera["logo-watermark-modal"].hint')}</span>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  appearance="secondaryOutlined"
                  className={styles.itemBtn}
                  onClick={handleCloseModal}
                >
                  {localeStore.t('camera["logo-watermark-modal"].cancel')}
                </Button>
                <Button
                  appearance="primary"
                  className={styles.itemBtn}
                  loading={logoWatermarkStatus === "saving"}
                  onClick={handleSaveLogoWatermark}
                >
                  {localeStore.t('camera["logo-watermark-modal"].save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default LogoWatermarkModal;
