import React from "react";
import "./index.scss";
import ExpandableLine from "../ExpandableLine";

const ErrorsList = ({ errors }) => {
  const [limit, setLimit] = React.useState(5);

  return (
    <div className="errors-list">
      {Object.entries(errors)
        .slice(0, limit)
        .map(([title, values], index) => (
          <React.Fragment key={index}>
            {values.length > 0 && (
              <ExpandableLine
                title={`${title} - click to see errors`}
                className="errors-list__item"
              >
                {values.map((text) => (
                  <div className="error-text" key={text}>
                    {" "}
                    {text}{" "}
                  </div>
                ))}
              </ExpandableLine>
            )}
          </React.Fragment>
        ))}

      {Object.entries(errors).length > limit && (
        <button className="primary-btn expand" onClick={() => setLimit(limit + 5)}>
          Show more errors
        </button>
      )}
    </div>
  );
};

export default ErrorsList;
